import React from "react";
import { Stack, Typography, ButtonBase } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { localization } from "../localizationV2";
import ChoosenLocations from "../ChoosenLocations";

export const EditBtn = ({ onClick }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        height: "2em",
        width: isMatch ? "12em" : "6em",
        background: "#8ED8F8",
        borderRadius: "20px",
      }}
    >
      <Typography variant="body2" sx={{ color: "white", fontWeight: 700 }}>
        EDIT
      </Typography>
    </ButtonBase>
  );
};

const ChosenLocation = ({ state }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const displayDestination = state?.destination?.title || "Dropped pin";

  return (
    <Stack
      direction={"row"}
      sx={{ mt: 1.5, ml: 1, width: isMatch ? "96.5%" : "93%" }}
      justifyContent="space-between"
    >
      <ChoosenLocations
        destinationLocation={displayDestination}
        pickUpLocation={localization.DOPPED_PIN}
      />
      <Stack spacing={3}>
        <EditBtn
          onClick={() => {
            navigate("/pickup", { state: { destination: state.destination } });
          }}
        />
        <EditBtn
          onClick={() => {
            navigate("/destination", {
              state: {
                destination: {
                  latitude: state.destination.latitude,
                  longitude: state.destination.longitude,
                },
              },
            });
            // navigate("/destination", { state: { ...state } });
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ChosenLocation;
