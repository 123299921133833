import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Container, Stack, Typography } from "@mui/material";
import ConfirmBtn from "../ConfirmBtn";
import { useNavigate } from "react-router-dom";
import Boat from "./Boat";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { localization } from "../localizationV2";
import Map from "../Map";
import Header from "../Header";
import InvalidLocation from "../InvalidLocation";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebaseSetup";

const PickUpMap = () => {
  const [open, setOpen] = useState(false);
  const [gpsLocationDisplay, setGpsLocationDisplay] = useState(
    localization.LOCATE
  );
  const [invalidLocation, setInvalidLocation] = useState(false);
  const [openInvalidLocation, setOpenInvalidLocation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(analytics, "pickup_map", {});
  }, []);

  const locateMeMessage = gpsLocationDisplay === localization.LOCATE;

  const { state } = useLocation();

  const { handleSubmit, control } = useForm();

  console.log(state);

  const defaultProps = {
    pickup: {
      lat: 43.17,
      lng: 16.45,
    },
    center: {
      lat: 43.17,
      lng: 16.45,
    },
    zoom: 12,
  };

  let loadMap = (map, maps) => {
    map.data.loadGeoJson("/geojson.json");
    map.data.setStyle({ visible: false });
    map.data.setStyle({
      fillColor: "transparent",
      strokeWeight: 0.1,
    });

    window._google_maps = map;
    window._google_maps_maps = maps;
    window.pickupMarker = new maps.Marker({
      position: { lat: 43.172428, lng: 16.440816 },
      map,
      draggable: true,
      icon: {
        url: "/imagesV2/locationBlue.svg",
      },
    });

    if (state && state.pickup) {
      window.pickupMarker.setPosition({
        lat: state.pickup.latitude,
        lng: state.pickup.longitude,
      });
    }
    //changing center
    map.addListener("center_changed", () => {
      window.setTimeout(() => {
        map.panTo(window.pickupMarker.getPosition());
      }, 1000);
    });
    window.pickupMarker.addListener("dragend", () => {
      map.panTo(window.pickupMarker.getPosition());
      // detect which polygon from the geojson the marker is in
      map.data.forEach(function (feature) {
        if (feature.getGeometry().getType() === "Polygon") {
          // simplifying assumption, depends on data
          // just check first linear ring
          const poly = new maps.Polygon({
            path: feature.getGeometry().getAt(0).getArray(),
          });
          if (
            maps.geometry.poly.containsLocation(
              window.pickupMarker.getPosition(),
              poly
            )
          ) {
            console.log(feature);
            console.log("Location OK");
            setInvalidLocation(false);
            setOpenInvalidLocation(false);
          } else {
            setInvalidLocation(true);
            setOpenInvalidLocation(true);
            console.log("Invalid location");
          }
        }
      });
    });
  };

  const successGpsCallback = (position) => {
    const map = window._google_maps;
    const maps = window._google_maps_maps;

    window.pickupMarker.setPosition({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    window._google_maps.setZoom(12);
    window._google_maps.panTo(window.pickupMarker.getPosition());
    setGpsLocationDisplay(localization.LOCATE);

    map.data.forEach(function (feature) {
      if (feature.getGeometry().getType() === "Polygon") {
        // simplifying assumption, depends on data
        // just check first linear ring
        const poly = new maps.Polygon({
          path: feature.getGeometry().getAt(0).getArray(),
        });
        if (
            maps.geometry.poly.containsLocation(
                window.pickupMarker.getPosition(),
                poly
            )
        ) {
          console.log(feature);
          console.log("Location OK");
          setInvalidLocation(false);
          setOpenInvalidLocation(false);
        } else {
          setInvalidLocation(true);
          setOpenInvalidLocation(true);
          console.log("Invalid location");
        }
      }
    });
  };

  const errorGpsCallback = () => {
    setGpsLocationDisplay(localization.LOCATE_FAIL);
  };

  const findLocation = () => {
    if (!navigator.geolocation) {
      setGpsLocationDisplay("Geolocation is not supported by your browser");
    } else {
      setGpsLocationDisplay(localization.LOCATE_PROGRESS);
      window._google_maps.setZoom(8);
      window._google_maps.panTo(window.pickupMarker.getPosition());
      navigator.geolocation.getCurrentPosition(
        successGpsCallback,
        errorGpsCallback,
        {
          timeout: 10000
        }
      );
    }
  };

  const onAddPickup = (data) => {
    navigate("/confirmRide", {
      state: {
        ...state,
        destination: state.destination,
        pickup: {
          latitude: window.pickupMarker.getPosition().lat(),
          longitude: window.pickupMarker.getPosition().lng(),
          pickupInfo: data,
        },
      },
    });
    console.log(data);
  };

  const mapComponent = (
    <Map
      onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
      defaultProps={defaultProps}
      height={"90%"}
    />
  );

  return (
    <form onSubmit={handleSubmit(onAddPickup)}>
      <Container maxWidth="md" disableGutters>
        <Box sx={{ height: "100vh" }}>
          <Stack>
            <Header>{localization.PICK_UP.toLocaleUpperCase()}</Header>
            <Box
              sx={{ background: "#232955", minHeight: "3.5em" }}
              onClick={findLocation}
            >
              <Typography
                variant={locateMeMessage ? "body1" : "body2"}
                sx={{
                  color: "white",
                  fontWeight: locateMeMessage ? 600 : 400,
                  textAlign: "center",
                  m: locateMeMessage ? 2 : 1,
                }}
              >
                {gpsLocationDisplay}{" "}
              </Typography>
            </Box>
            <Box sx={{ background: "white", width: "100%", height: "2.5em" }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  textAlign: "center",
                  mt: 1,
                  color: "#232955",
                }}
              >
                {localization.OR_PIN_DEST}{" "}
              </Typography>
            </Box>
          </Stack>
          {mapComponent}
          <ConfirmBtn
            type="submit"
            opacity={!invalidLocation ? "" : 0.2}
            disabled={!invalidLocation ? false : true}
            boatSwitch={
              <Boat open={open} setOpen={setOpen} control={control} />
            }
          >
            {localization.CONFIRM_PICKUP.toLocaleUpperCase()}
          </ConfirmBtn>
        </Box>

        {openInvalidLocation === true && (
          <InvalidLocation
            openInvalidLocation={openInvalidLocation}
            setOpenInvalidLocation={setOpenInvalidLocation}
          />
        )}
      </Container>
    </form>
  );
};

export default PickUpMap;
