import React, {useCallback, useContext} from "react";
import {Stack, Typography, TextField} from "@mui/material";
import {useTheme} from "@emotion/react";
import CustomizedRating from "./CustomizedRating";
import {useForm, Controller} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {localization} from "../localizationV2";
import ContinueBtn from "../ContinueBtn";
import {styled} from "@mui/system";
import {ApiContext} from "../../App";
import {useFetch} from "use-http";

const BlueTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#232955",
    },

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#FFFFFF",
        },

        "&.Mui-focused fieldset": {
            borderColor: "#232955",
        },
    },
});

function RatingContainer() {
    const {state} = useLocation();
    const navigate = useNavigate();

    const API = useContext(ApiContext);
    const {response, post} = useFetch(API.host);

    const theme = useTheme();

    const {handleSubmit, register, control} = useForm();


    const onSubmit = useCallback(
        async (data) => {
            const body = {
                rideId: state.rideId,
                ratings: {
                    skipper: (+data.skipper),
                    boat: (+data.boat),
                    ride: (+data.ride),
                    experienceText: data.experienceText
                }
            };
            await post(`/api/v1/guest/ride/rate`, body);

            if (response.ok) {
                navigate(`/`);
            }
        },
        [navigate, post, state, response]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Stack justifyContent="center" alignItems="center" spacing={1.5}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: theme.palette.white,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        {localization.RATING_TEXT}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{color: theme.palette.blue.dark, fontWeight: 700}}
                    >
                        {localization.SKIPPER.toLocaleUpperCase()}
                    </Typography>

                    <Controller
                        control={control}
                        name="skipper"
                        rules={{required: true}}
                        render={({field}) => (
                            <CustomizedRating
                                onChange={(e) => field.onChange(e)}
                                selected={field.value}
                            />
                        )}
                    />
                    <Typography
                        variant="body2"
                        sx={{color: theme.palette.blue.dark, fontWeight: 700}}
                    >
                        {localization.BOAT.toUpperCase()}
                    </Typography>

                    <Controller
                        control={control}
                        rules={{required: true}}
                        name="boat"
                        render={({field}) => (
                            <CustomizedRating
                                onChange={(e) => field.onChange(e)}
                                selected={field.value}
                            />
                        )}
                    />
                    <Typography
                        variant="body2"
                        sx={{color: theme.palette.blue.dark, fontWeight: 700}}
                    >
                        {localization.RIDE.toLocaleUpperCase()}
                    </Typography>

                    <Controller
                        control={control}
                        rules={{required: true}}
                        name="ride"
                        render={({field}) => (
                            <CustomizedRating
                                onChange={(e) => field.onChange(e)}
                                selected={field.value}
                            />
                        )}
                    />
                </Stack>
                <Typography sx={{color: theme.palette.blue.dark, fontWeight: 600}}>
                    {localization.EXPERIENCE}
                </Typography>
                <BlueTextField
                    label={localization.MESSAGE}
                    multiline
                    rows={3}
                    fullWidth
                    name={"experienceText"}
                    {...register("experienceText")}
                />{" "}
            </Stack>
            <ContinueBtn pt={2} type="submit">
                {localization.SEND.toLocaleUpperCase()}
            </ContinueBtn>
        </form>
    );
}

export default RatingContainer;
