import React, { useContext, useState, useEffect, useCallback } from "react";
import { Container, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { localization } from "../localizationV2";
import ContactSupport from "../LoadingScreen/ContactSupport";
import BoatAndSkipper from "../RideConfirmed/BoatAndSkipper";
import Map from "../Map";
import ActiveRideInfo from "./ActiveRideInfo";
import { ApiContext } from "../../App";
import { useNavigate, useLocation } from "react-router-dom";
import DeclineBtn from "../DeclineBtn";
import HeaderHome from "../HeaderHome";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebaseSetup";

const GoogleMapsHelper = {
  boatIcon: "/imagesV2/AhoyBoatV2.svg",
  car: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
  animateMove: (marker, current_lat, current_lng, latitude, longitude) => {
    const num = 100;
    const deltalat = (latitude - current_lat) / num;
    const deltalng = (longitude - current_lng) / num;

    for (let i = 0; i < num; i++) {
      (function (ind) {
        setTimeout(function () {
          let lat = marker.position.lat();
          let lng = marker.position.lng();
          lat += deltalat;
          lng += deltalng;
          marker.setPosition({ lat, lng });
        }, 10 * ind);
      })(i);
    }
  },
  rotateImage: (rotation, imageSrc) => {
    const selector = document.querySelector("img[src='" + imageSrc + "']");
    if (selector) {
      selector.style.transform = `rotate(${rotation}deg)`;
    }
  },
};

const ActiveRide = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const API = useContext(ApiContext);
  const { state } = useLocation();
  const [activeRideData, setActiveRideData] = useState(null);
  const [skipperCoordinates, setSkipperCoordinates] = useState(null);

  const updateBoatLocation = useCallback(
    ({ latitude, longitude, rotation }) => {
      if (window.boatMarker) {
        GoogleMapsHelper.rotateImage(rotation, GoogleMapsHelper.boatIcon);
        GoogleMapsHelper.animateMove(
          window.boatMarker,
          window.boatMarker.getPosition().lat(),
          window.boatMarker.getPosition().lng(),
          latitude,
          longitude
        );
      }
    },
    []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!state) {
      navigate("/", {});
    }
    setActiveRideData(state);
    logEvent(analytics, "active_ride", { id: state.id });
  }, [state, navigate]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await fetch(`${API.host}/api/v1/ride/${state.id}`);
      const body = await response.json();

      if (
        body.status === "ACCEPTED" ||
        body.status === "ACTIVE" ||
        body.status === "IN_PICKUP" ||
        body.status === "IN_TRANSIT"
      ) {
        setActiveRideData(body);
      } else {
        navigate(`/ride/${state.id}`, {});
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [navigate, state, API.host]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        activeRideData.status === "IN_PICKUP" ||
        activeRideData.status === "IN_TRANSIT"
      ) {
        const skipperLocationResponse = await fetch(
          `${API.host}/api/v1/ride/${state.id}/skipper/location`
        );
        const data = await skipperLocationResponse.json().catch((e) => {});
        setSkipperCoordinates(data);
        updateBoatLocation(data);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate, state, API.host, activeRideData, updateBoatLocation]);

  let loadMap = (map, maps) => {
    window.boatMarker = new maps.Marker({
      position: {
        lat: skipperCoordinates.latitude,
        lng: skipperCoordinates.longitude,
      },
      map,
      draggable: false,
      icon: {
        url: GoogleMapsHelper.boatIcon,
        anchor: new maps.Point(50, 50),
        size: new maps.Size(100, 100),
        scaledSize: new maps.Size(100, 100),
      },
      optimized: false,
    });

    window.pickupMarker = new maps.Marker({
      position: { lat: state.pickup.latitude, lng: state.pickup.longitude },
      map,
      draggable: false,
      icon: {
        url: "/imagesV2/locationBlue.svg",
      },
    });

    window.destinationMarker = new maps.Marker({
      map,
      position: {
        lat: state.destination.latitude,
        lng: state.destination.longitude,
      },
      draggable: false,
      icon: {
        url: "/imagesV2/locationRed.svg",
      },
    });
  };

  const defaultProps = {
    pickup: {
      lat: 43.17,
      lng: 16.45,
    },
    center: {
      lat: 43.17,
      lng: 16.45,
    },
    zoom: 9,
  };

  const mapComponent = (
    <Map
      onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
      defaultProps={defaultProps}
      height={350}
    />
  );

  const removeCancel = activeRideData?.status === "IN_TRANSIT";

  return (
    <>
      {" "}
      <HeaderHome>{localization.ACTIVE_RIDE.toLocaleUpperCase()} </HeaderHome>
      <Container maxWidth="md" sx={{ pb: 2 }}>
        <Stack sx={{ mt: 4 }} spacing={2.5}>
          <Stack spacing={1}>
            {activeRideData && (
              <Typography
                variant="body1"
                sx={{ color: theme.palette.white, fontWeight: 700 }}
              >
                {localization.RIDE_ID} {activeRideData.rideDesignation}
              </Typography>
            )}
            {activeRideData && (
              <Typography
                variant="body1"
                sx={{ color: theme.palette.blue.dark, fontWeight: 600 }}
              >
                {(activeRideData.status === "ACCEPTED" &&
                  localization.ACTIVE) ||
                  (activeRideData.status === "IN_PICKUP" &&
                    localization.PICKUP) ||
                  (activeRideData?.status === "IN_TRANSIT" &&
                    localization.TRANSIT)}
              </Typography>
            )}
          </Stack>
          {activeRideData && (
            <ActiveRideInfo
              destination={activeRideData.destination}
              pickup={activeRideData.pickup}
              pickupTime={activeRideData.estimatedPickupTime}
              status={activeRideData.status}
              numberOfTickets={activeRideData.numberOfTickets}
              scheduleRideAt={activeRideData.scheduleRideAt}
            />
          )}
          {skipperCoordinates && mapComponent}
          {activeRideData && (
            <BoatAndSkipper
              skipperName={activeRideData.skipper.displayName}
              boatName={activeRideData.boat.model}
              skipperImage={activeRideData.skipper.image.url}
              boatImage={activeRideData.boat.image.url}
            />
          )}

          {!removeCancel && (
            <DeclineBtn
              onClick={() =>
                navigate("/decline", { state: { activeRideData } })
              }
            >
              {localization.CANCEL.toLocaleUpperCase()}
            </DeclineBtn>
          )}

          <ContactSupport />
        </Stack>
      </Container>
    </>
  );
};

export default ActiveRide;
