import React, {useCallback, useContext, useState, useEffect} from "react";
import {ApiContext} from "../../App";
import HomeV2 from "./HomeV2";
import {useSearchParams} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebaseSetup";

const HomeScreen = () => {
    const API = useContext(ApiContext);
    const [locations, setLocations] = useState("");

    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.sessionStorage.setItem('code', searchParams.get('code'))
        logEvent(analytics, "qr_code", { code: searchParams.get('code') });
    }, [searchParams])

    console.log(searchParams.get('code'))
    
    const getLocations = useCallback(async () => {
        const response = await fetch(`${API.host}/api/v1/popularlocation/featured`);
        const data = await response.json();
        setLocations(data);
    }, [API.host]);

    useEffect(() => {
        getLocations().catch((e) => console.error(e));
    }, [getLocations]);

    return <div>{locations && <HomeV2 locations={locations}/>}</div>;
};

export default HomeScreen;
