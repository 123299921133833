import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { localization } from "../localizationV2";

export const PickupTime = ({ text, time }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.white,
        height: "2.5em",
        borderBottom: `1px solid ${theme.palette.grey}`,
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        spacing={1}
        sx={{ mt: 1, ml: 3 }}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.blue.dark, fontWeight: 700 }}
        >
          {text}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.red, fontWeight: 800, fontSize: 16 }}
        >
          {time}
        </Typography>
      </Stack>
    </Box>
  );
};
