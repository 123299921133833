import { Typography, Stack } from "@mui/material";
import React from "react";
import { localization } from "../localizationV2";

const Text = ({ children }) => {
  return (
    <Typography
      fontSize={"body1"}
      sx={{
        fontWeight: 800,
        color: "#232955",
        letterSpacing: 0.4,
      }}
    >
      {children}
    </Typography>
  );
};

const SafeRide = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={-0.5}
    >
      <Text>{localization.INSURED}</Text>
      <Text>{localization.HIDDER_FEE}</Text>
      <Text>{localization.SECURE_PAYMENTS}</Text>
    </Stack>
  );
};

export default SafeRide;
