import React from "react";
import BackBtn from "./BackBtn";
import { Typography, Container, Box } from "@mui/material";
import { AppBar, Toolbar } from "@mui/material";
import { useTheme } from "@emotion/react";

const Header = ({ children }) => {
  const theme = useTheme();
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ background: theme.palette.blue.light }}
    >
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <BackBtn />
          </Box>
          <Typography
            variant="body1"
            noWrap
            sx={{
              mr: 2,
              flexGrow: 1,
              fontWeight: 700,
              color: "white",
            }}
          >
            {children}{" "}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
