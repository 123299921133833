import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { localization } from "./localizationV2";

const ChoosenLocations = ({ pickUpLocation, destinationLocation, m }) => {
  return (
    <Stack direction={"row"} spacing={1} sx={{ m: m }}>
      <Stack
        sx={{ height: "100%" }}
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        spacing={-0.2}
      >
        <LocationOnIcon sx={{ color: "#232955", fontSize: "2.5em" }} />
        <Box
          sx={{
            height: "0.7em",
            borderLeft: `2px dashed black`,
          }}
        />
        <LocationOnIcon sx={{ color: "#FF2E00", fontSize: "2.5em" }} />
      </Stack>
      <Stack spacing={1}>
        <Stack>
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, color: "#232955" }}
          >
            {localization.PICK.toLocaleUpperCase()}{" "}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 600, color: "#9A9A9A" }}
          >
            {pickUpLocation}{" "}
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, color: "#232955" }}
          >
            {localization.DESTINATION.toLocaleUpperCase()}{" "}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 600, color: "#9A9A9A" }}
          >
            {destinationLocation}{" "}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChoosenLocations;
