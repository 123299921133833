import { Box, Stack } from "@mui/material";
import React from "react";
import ChoosenLocations from "../ChoosenLocations";
import { useTheme } from "@emotion/react";
import Passengers from "../RideConfirmed/Passengers";
// import Passengers from "../ConfirmationScreen/Passengers";
import { PickupTime } from "../RideConfirmed/PickupTime";
// import OnBoat from "../RideConfirmed/OnBoat";
// import ReturnTicket from "../RideConfirmed/Return";
import Notes from "../RideConfirmed/Notes";
import moment from "moment";
import {ScheduledAtTime} from "../RideConfirmed/ScheduledAtTime";
import {localization} from "../localizationV2";

const ActiveRideInfo = ({
  destination,
  pickup,
  pickupTime,
  status,
  numberOfTickets,
  scheduleRideAt
}) => {
  const theme = useTheme();
  const pickupNotes = pickup.pickupNote.note;
  const dest = destination.title ?? "Dropped pin";
  const pick = pickup.title ?? "Dropped pin";
  // const name = pickup.pickupNote.boatInfo.name;
  // const type = pickup.pickupNote.boatInfo.type;
  // const color = pickup.pickupNote.boatInfo.color;
  // const onBoat =
  //   Object.keys(pickup.pickupNote.boatInfo).length === 0 ? "NO" : "YES";

  const time = moment(pickupTime).fromNow();
  const wrongStatus = status === "IN_TRANSIT";
  const emptyNotes = pickupNotes === "";

  return (
    <Stack>
      <Box
        sx={{
          height: "6.5em",
          background: theme.palette.white,
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          borderBottom: `1px solid ${theme.palette.grey}`,
        }}
      >
        <ChoosenLocations
          m={1}
          pickUpLocation={pick}
          destinationLocation={dest}
        />
      </Box>
      {!wrongStatus && scheduleRideAt && <PickupTime text={localization.SHORT_PICKUP_TIME.toLocaleUpperCase()} time={moment(pickupTime).format("DD.MM.yyyy HH:mm")} />}{" "}

      {!wrongStatus && !scheduleRideAt && <PickupTime text={localization.PICKUP_TIME.toLocaleUpperCase()} time={time} />}{" "}
      {!emptyNotes && <Notes notes={pickupNotes} />}{" "}
      <Passengers passengers={numberOfTickets} />
      {/* <ReturnTicket /> */}
      {/* <OnBoat answer1={type} answer2={color} answer3={name} onBoat={onBoat} />{" "} */}
    </Stack>
  );
};

export default ActiveRideInfo;
