import React, { useState, useEffect, useCallback, useContext } from "react";
import ChoosenLocations from "../ChoosenLocations";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import { ExpandMore } from "../ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { ApiContext } from "../../App";
import { useFetch } from "use-http";

const RideDetails = ({ destination, pickup }) => {
  const [expanded, setExpanded] = useState(false);
  const [mapView, setMapView] = useState();

  const API = useContext(ApiContext);

  const { post: getMap, response: mapResponse } = useFetch(API.host);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));

  const destinationName = destination.title ?? "Dropped pin";
  const pickupName = pickup.title ?? "Dropped pin";

  const getMapView = useCallback(
    async (pickup, destination) => {
      const map = await getMap(`/api/v1/mapview`, { pickup, destination });
      if (mapResponse.ok) setMapView(map);
    },
    [getMap, mapResponse]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    getMapView(pickup, destination);
  }, [getMapView, destination, pickup]);

  return (
    <Stack>
      <Box
        sx={{
          background: "white",
          height: "6.5em",
          width: "100%",
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          borderBottom: expanded ? "" : "1px solid #9A9A9A",
        }}
      >
        <Stack
          direction={"row"}
          sx={{ m: 1, width: isMatch ? "93%" : "96%" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <ChoosenLocations
            pickUpLocation={pickupName}
            destinationLocation={destinationName}
          />
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon
              sx={{
                fontSize: 45,
                color: "#232955",
                ":hover": { color: theme.palette.blue.light },
              }}
            />
          </ExpandMore>
        </Stack>
      </Box>
      <Collapse in={expanded} timeout={20} unmountOnExit>
        <Box
          sx={{
            background: "white",
            borderBottom: expanded ? "" : "1px solid #9A9A9A",
          }}
        >
          {mapView && (
            <img
              src={mapView}
              alt=""
              style={{
                zIndex: 1,
                width: "100%",
                height: isMatch ? "auto" : "20em",
              }}
            />
          )}
        </Box>
      </Collapse>
    </Stack>
  );
};

export default RideDetails;
