import React from "react";
import { Box, Stack, ButtonBase, Container } from "@mui/material";
import { Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { localization } from "../localizationV2";
import { useNavigate } from "react-router-dom";

const PopularLocations = ({
  activeLocation,
  setActiveLocation,
  setLocationIndex,
  locations,
}) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" disableGutters sx={{ mt: 3, zIndex: 3 }}>
      <Typography
        sx={{
          fontWeight: 900,
          mt: -3,
          color: "#232955",
          ml: 2,
          letterSpacing: 0.5,
        }}
      >
        {localization.CHOOSE_LOCAL.toLocaleUpperCase()}{" "}
      </Typography>
      <div style={{ width: "100%" }}>
        <Box
          display="flex"
          sx={{
            whiteSpace: "nowrap",
            overflowX: "auto",
            p: 2,
            borderRadius: 2,
            height: isMatch ? "18em" : "17em",
          }}
        >
          <Stack direction="row" spacing={1}>
            {locations.map(({ image, name, location, type, gps }, index) => {
              const latitude = gps.latitude;
              const longitude = gps.longitude;
              const title = name;
              return (
                <Box
                  key={index}
                  sx={{
                    height: isMatch ? "17em" : "15em",
                    width: isMatch ? "13em" : "11.5em",
                    borderRadius: "25px",
                    background: "white",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: "95%", mt: 0.3, mr: 0.5 }}
                    justifyContent="space-between"
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="flex-start"
                      alignItems="flex-end"
                      spacing={0}
                    >
                      <LocationOnIcon
                        sx={{
                          fontSize: 28,
                          mb: 0.5,
                          color:
                            activeLocation === locations[index].name
                              ? "#8ED8F8"
                              : "#232955",
                        }}
                      />
                      <Stack direction="column" spacing={0}>
                        <Stack spacing={-0.5}>
                          <Typography
                            sx={{
                              fontSize: 13,
                              color:
                                activeLocation === locations[index].name
                                  ? "#8ED8F8"
                                  : "#232955",
                              fontWeight: 800,
                              letterSpacing: 0.5,
                              whiteSpace: "nowrap",
                              width: "7.7em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {name},
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              color:
                                activeLocation === locations[index].name
                                  ? "#8ED8F8"
                                  : "#232955",
                              fontWeight: 800,
                              letterSpacing: 0.5,
                              whiteSpace: "nowrap",
                              width: "7em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {location}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            mt: -0.2,
                            fontSize: 11,
                            color:
                              activeLocation === locations[index].name
                                ? "#8ED8F8"
                                : "#232955",
                            fontWeight: 800,
                            letterSpacing: 0.5,
                            whiteSpace: "nowrap",
                            width: "8em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {type === "BAR_RESTAURANT" ? "RESTAURANT" : type}
                        </Typography>
                      </Stack>
                    </Stack>

                    <ButtonBase
                      onClick={() => {
                        setLocationIndex(index);
                        setActiveLocation(locations[index].name);
                        navigate("/pickup", {
                          state: {
                            destination: {
                              title,
                              latitude,
                              longitude,
                            },
                          },
                        });
                      }}
                      disableRipple
                      sx={{
                        background:
                          activeLocation === locations[index].name
                            ? "#8ED8F8"
                            : "#232955",
                        height: "3em",
                        width: "3em",
                        borderRadius: "30px",
                      }}
                    >
                      <img src="/imagesV2/ArrowSmall.svg" alt="" />
                    </ButtonBase>
                  </Stack>
                  <ButtonBase
                    onClick={() => {
                      setActiveLocation(locations[index].name);
                      setLocationIndex(index);
                    }}
                    disableRipple
                    sx={{
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                      height: "85%",
                      width: "100%",
                        backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 70%),url(${image.url})`,
                      backgroundSize: "cover",
                    }}
                  ></ButtonBase>
                </Box>
              );
            })}
          </Stack>
        </Box>
      </div>
    </Container>
  );
};

export default PopularLocations;
