import React, {useEffect, useState, useCallback, useContext} from "react";
import {Container, Stack, Typography} from "@mui/material";
import {localization} from "../localizationV2";
import RideDetails from "./RideDetails";
import BoatAndSkipper from "./BoatAndSkipper";
import ContactSupport from "../LoadingScreen/ContactSupport";
// import OnBoat from "./OnBoat";
// import Return from "./Return";
import Notes from "./Notes";
import DeclineBtn from "../DeclineBtn";
import {useNavigate} from "react-router-dom";
import ContinueBtn from "../ContinueBtn";
import {useFetch} from "use-http";
import {ApiContext} from "../../App";
import {useLocation} from "react-router-dom";
import moment from "moment";
import {useTheme} from "@emotion/react";
import Passengers from "./Passengers";
import {PickupTime} from "./PickupTime";
import {ScheduledAtTime} from "./ScheduledAtTime";

const RideConfirmed = () => {
    const theme = useTheme();

    const API = useContext(ApiContext);
    const {response, post} = useFetch(API.host);
    const navigate = useNavigate();
    const [offer, setOffer] = useState(null);
    const {state} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!state) {
            navigate("/", {});
        }
        if (state && state.id && state.status !== "OFFERED") {
            navigate(`/ride/${state.id}`);
        }
        setOffer(state.rideOffers[0]);

        const interval = setInterval(async () => {
            const response = await fetch(`${API.host}/api/v1/ride/${state.id}`);
            const body = await response.json();
            if (body.status === "ACCEPTED") {
                clearInterval(interval);
                navigate(`/ride/${state.id}`, {
                    state: {...body},
                });
            }
        }, 500);
        return () => clearInterval(interval);

    }, [state, navigate, API.host]);

    // confirm ride
    const confirmRide = useCallback(async () => {
        await post(`/api/v1/guest/ride/offer`, {
            rideId: state.id,
            offerId: offer.id,
        });
        if (response.ok) {
            navigate(`/ride/${state.id}`);
        }
    }, [post, response, navigate, offer, state]);

    const pickupNotes = state.pickup.pickupNotes;
    const destination = state.destination;
    const pickup = state.pickup;
    const emptyPickup = state.pickup.location === "";
    const numberOfTickets = state.numberOfTickets;
    // const onBoat =
    //   Object.keys(state.pickup.pickupNote.boatInfo).length === 0 ? "NO" : "YES";
    // const name = state.pickup.pickupNote.boatInfo.name;
    // const type = state.pickup.pickupNote.boatInfo.type;
    // const color = state.pickup.pickupNote.boatInfo.color;

    //decline ride
    const declineRide = useCallback(async () => {
        const requestOptions = {
            method: "DELETE",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                rideId: state.id,
                offerId: offer.id,
            }),
        };
        await fetch(`${API.host}/api/v1/guest/ride/offer`, requestOptions);
        navigate(`/ride/${state.id}`);
    }, [offer, state, navigate, API.host]);

    return (
        <>
            <Typography
                sx={{
                    textAlign: "center",
                    color: theme.palette.white,
                    fontWeight: 700,
                    mt: 3,
                }}
            >
                {localization.CHECKOUT.toLocaleUpperCase()}
            </Typography>{" "}
            <Container maxWidth="md" sx={{pb: 3}}>
                <Stack sx={{mt: 4}} spacing={2.5}>
                    <Typography
                        variant="body1"
                        sx={{color: "#232955", fontWeight: 600}}
                    >
                        {localization.CONFIRM_DECLINE}
                    </Typography>
                    <Stack spacing={1}>
                        <ContinueBtn onClick={confirmRide}>
                            {localization.CONFIRM.toLocaleUpperCase()}
                        </ContinueBtn>
                        <DeclineBtn onClick={declineRide}>
                            {localization.DECLINE}
                        </DeclineBtn>
                    </Stack>
                    <Stack>
                        <RideDetails destination={destination} pickup={pickup}/>
                        {offer && state.scheduleRideAt && (
                            <PickupTime text={localization.SHORT_PICKUP_TIME.toLocaleUpperCase()}
                                        time={moment(offer.estimatedPickupTime).format("DD.MM.yyyy HH:mm")}/>
                        )}{" "}
                        {offer && !state.scheduleRideAt && (
                            <PickupTime text={localization.PICKUP_TIME.toLocaleUpperCase()}
                                        time={moment(offer.estimatedPickupTime).fromNow()}/>
                        )}{" "}
                        <Passengers passengers={numberOfTickets}/>
                        {/* <Return /> */}
                        {emptyPickup && <Notes notes={pickupNotes}/>}
                        {/* <OnBoat
              answer1={type}
              answer2={color}
              answer3={name}
              onBoat={onBoat}
            /> */}
                    </Stack>
                    {offer && (
                        <BoatAndSkipper
                            skipperName={offer.skipper.displayName}
                            boatName={offer.boat.model}
                            skipperImage={offer.skipper.image.url}
                            boatImage={offer.boat.image.url}
                        />
                    )}
                    <ContactSupport/>
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.blue.dark,
                            fontWeight: 600,
                            textAlign: "center",
                        }}
                    >
                        {localization.RIDE_ID} {state.rideDesignation}
                    </Typography>
                </Stack>
            </Container>
        </>
    );
};

export default RideConfirmed;
