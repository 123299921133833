import React, { useEffect, useCallback, useContext } from "react";
import { Container, Typography, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { localization } from "../localizationV2";
import ContinueBtn from "../ContinueBtn";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiContext } from "../../App";
import DeclineBox from "./DeclineBox";
import Header from "../Header";
import { useTheme } from "@emotion/react";

const DeclineRide = () => {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();
  const API = useContext(ApiContext);

  console.log(state);

  //decline ride
  const cancelRide = useCallback(
    async (data) => {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          rideId: state.activeRideData.id,
          cancellationReason: `Category: ${data.value} | Message: ${data.message}`,
        }),
      };

      await fetch(`${API.host}/api/v1/guest/ride`, requestOptions);
      navigate(`/ride/${state.activeRideData.id}`, {
        state: state.activeRideData.skipperInPickupAt,
      });
    },
    [state, navigate, API.host]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { handleSubmit, register, control } = useForm();

  return (
    <form onSubmit={handleSubmit(cancelRide)}>
      <Header>{localization.DECLINED.toLocaleUpperCase()}</Header>
      <Container maxWidth="md" sx={{ pt: 1, pb: 3 }}>
        <Stack sx={{ mt: 4 }} spacing={3}>
          <Stack>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.blue.dark, fontWeight: 700 }}
            >
              {localization.RIDE_CANCELED}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.blue.dark, fontWeight: 700 }}
            >
              {localization.RIDE_CANCELED1}
            </Typography>
          </Stack>
          <Typography variant="body1" sx={{ color: "white", fontWeight: 600 }}>
            {localization.RIDE_CANCELED2}
          </Typography>
          <DeclineBox register={register} control={control} />
          <ContinueBtn type="submit">
            {localization.SEND.toLocaleUpperCase()}
          </ContinueBtn>
        </Stack>
      </Container>
    </form>
  );
};

export default DeclineRide;
