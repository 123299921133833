import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { Container, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { localization } from "./localizationV2";
import { styled } from "@mui/system";

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: "#8ED8F8",
  fontWeight: 800,
}));

const BodyText = styled(Typography)(({ theme }) => ({
  color: "white",
  fontWeight: 400,
  fontSize: 14,
  textAlign: "justify",
}));

const Faq = () => {
  const navigate = useNavigate();
  return (
    <Box
      disableGutters
      sx={{
        background: "#232955",
        minHeight: "100vh",
        pb: 2,
      }}
    >
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ top: 0, background: "#232955" }}
      >
        <Container maxWidth="md" disableGutters>
          <Toolbar>
            <Stack
              justifyContent="space-between"
              alignItems="flex-start"
              direction={"row"}
              sx={{ width: "100%" }}
            >
              <Box sx={{ width: "3em", height: "1em" }} />
              <IconButton
                onClick={() => {
                  navigate("/");
                }}
              >
                <CloseIcon sx={{ color: "white" }} fontSize="large" />
              </IconButton>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="md">
        <Stack spacing={3} sx={{ mt: 6, pt: 2 }}>
          <Stack spacing={1}>
            <TitleText>{localization.WHO_WE_ARE}</TitleText>

            <BodyText>{localization.FAQ_TEXT_1}</BodyText>
            <BodyText>{localization.FAQ_TEXT_2}</BodyText>
            <BodyText>{localization.FAQ_TEXT_3}</BodyText>
          </Stack>

          <Stack spacing={1}>
            <TitleText>{localization.TOP_7}</TitleText>
            <BodyText>{localization.FAQ_TEXT_4}</BodyText>
            <BodyText>{localization.FAQ_TEXT_5}</BodyText>
            <BodyText>{localization.FAQ_TEXT_6}</BodyText>
            <BodyText>{localization.FAQ_TEXT_7}</BodyText>
            <BodyText>{localization.FAQ_TEXT_8}</BodyText>
            <BodyText>{localization.FAQ_TEXT_9}</BodyText>
            <BodyText>{localization.FAQ_TEXT_10}</BodyText>
          </Stack>

          <img src="imagesV2/LogoLight.svg" alt="" style={{ width: "10em" }} />
        </Stack>
      </Container>
    </Box>
  );
};

export default Faq;
