import React, { useEffect } from "react";
import { Stack, Typography, Container, Box } from "@mui/material";
import { localization } from "../localizationV2";
import { useTheme } from "@emotion/react";
import RatingContainer from "./RatingContainer";
import { useLocation } from "react-router-dom";
import BoatAndSkipper from "../RideConfirmed/BoatAndSkipper";

function RateRide() {
  const theme = useTheme();
  const { state } = useLocation();

  const name = state?.skipperInfo?.displayName;
  const boatName = state?.boatInfo?.model;
  const skipperImage = state?.skipperInfo?.image.url;
  const boatImage = state?.boatInfo?.image.url;


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(state);
  return (
    <>
      <Container maxWidth="md" sx={{ paddingBottom: "1em", pt: 1 }}>
        <Stack
          sx={{ mt: 2 }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: theme.palette.white,
              fontWeight: 700,
              pb: 3,
            }}
          >
            {localization.YOUR_TRIP.toLocaleUpperCase()}{" "}
          </Typography>
          <BoatAndSkipper
            skipperName={name}
            boatName={boatName}
            skipperImage={skipperImage}
            boatImage={boatImage}
          />

          <Box
            sx={{
              background: theme.palette.text.main,
              width: "100%",
              height: "0.5px",
            }}
          />
          <RatingContainer />
        </Stack>
      </Container>
    </>
  );
}

export default RateRide;
