import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import SearchIcon from "@mui/icons-material/Search";
import {Stack, Typography} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {Container} from "@mui/material";
import {localization} from "../localizationV2";

import {useNavigate} from "react-router-dom";
import TimePickerDialog from "./TimePickerDialog";

export default function WhereToBtn() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Container maxWidth="md" sx={{zIndex: 2}}>
            <Stack direction="column" alignItems="center" sx={{width: "100%"}}>
                <Stack
                    direction={"row"}
                    sx={{mt: 2, width: "100%"}}
                    justifyContent="flex-start"
                >
                    <ButtonBase
                        onClick={() => navigate("/search")}
                        sx={{
                            background: "white",
                            borderTopLeftRadius: "30px",
                            borderBottomLeftRadius: "30px",
                            width: "100%",
                            height: "4.5em",
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            sx={{width: "95%"}}
                        >
                            {" "}
                            <SearchIcon sx={{color: "#242753", ml: 1, fontSize: 45}}/>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    color: "#242753",
                                    textAlign: "left",
                                }}
                            >
                                {localization.WHERE_TO}
                            </Typography>
                        </Stack>
                    </ButtonBase>
                    <ButtonBase
                        onClick={handleClickOpen}
                        sx={{
                            background: "#242753",
                            color: "#8ED8F8",
                            width: "8em",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            height: "4.5em",
                        }}
                    >
                        <AccessTimeIcon fontSize="large"/>
                    </ButtonBase>
                    <TimePickerDialog handleClose={handleClose} open={open}/>
                </Stack>
            </Stack>
        </Container>
    );
}
