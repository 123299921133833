import React, {useEffect, useContext, useCallback, useState} from "react";
import {
    Container,
    Box,
    Stack,
    FormControl,
    FormHelperText,
    InputBase,
    Typography,
} from "@mui/material";
import {useForm} from "react-hook-form";
import {useNavigate, useLocation} from "react-router-dom";
import {localization} from "../localizationV2";
import PrivacyAndCookies from "./PrivacyAndCookies";
import Header from "../Header";
import {useTheme} from "@emotion/react";
import {useFetch} from "use-http";
import {ApiContext} from "../../App";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.css";
import {Controller} from "react-hook-form";
import PayButton from "../Payment/PayButton";

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const phonePattern =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

const Card = ({children, border}) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "3.5em",
                borderRadius: "25px",
                background: "white",
                border: border,
            }}
        >
            {children}
        </Box>
    );
};

const CardNumber = ({onClick, border}) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                width: "100%",
                height: "3.5em",
                borderRadius: "25px",
                background: "white",
                border: border,
            }}
        >
            <Typography sx={{color: "#a9a9a9", mt: 2, ml: 2}}>
                {localization.PHONE}
            </Typography>
        </Box>
    );
};

const User = () => {
    const theme = useTheme();
    const API = useContext(ApiContext);
    const {response, post} = useFetch(API.host);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [phoneInputVisible, setPhoneInputVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        formState: {errors},
        register,
        control,
    } = useForm();

    console.log(state)


    useEffect(() => {

        window.scrollTo(0, 0);
        if (!state) {
            navigate("/", {});
        }
    }, [state, navigate]);

    const submitPayment = useCallback(
        async (data) => {
            setIsLoading(true);
            const body = {
                ...state,
                uid: "guest_id",
                email: data.email,
                phoneNumber: `+${data.phoneNumber}`,
                displayName: data.displayName,
                affiliateCode: sessionStorage.getItem('code')
            };
            const newRide = await post(`/api/v1/guest/ride/request`, body);

            if (response.ok) {
                navigate(`/payment/${newRide.rideId}`);
            }
            console.log(data);
        },
        [navigate, post, state, response]
    );


    return (
        <form onSubmit={handleSubmit(submitPayment)}>
            <Header>{localization.CHECKOUT.toLocaleUpperCase()}</Header>
            <Container maxWidth="md">
                <Stack sx={{mt: 10, mb: 3}} spacing={3.5}>
                    {/* 1 email */}
                    <Stack spacing={errors?.email ? 3.5 : 1}>
                        <Card border={errors?.email ? "1px solid #FF2E00" : ""}>
                            <FormControl
                                sx={{
                                    height: "100%",
                                    mt: 1.5,
                                    ml: 2,
                                    width: "90%",
                                }}
                            >
                                <InputBase
                                    placeholder={localization.EMAIL}
                                    type={"text"}
                                    name="email"
                                    autoCapitalize="none"
                                    autoComplete="off"
                                    {...register("email", {
                                        required: true,
                                        pattern: emailPattern,
                                    })}
                                    error={Boolean(errors.email)}
                                    label="Email Address"
                                />
                                <FormHelperText
                                    error={Boolean(errors.email)}
                                    sx={{
                                        mt: 1.5,
                                        "&.MuiFormHelperText-root": {
                                            color: errors?.email
                                                ? theme.palette.red
                                                : theme.palette.blue.dark,
                                        },
                                    }}
                                >
                                    {" "}
                                    {(errors?.email?.type === "required" &&
                                            localization.EMAIL_REQUIRED) ||
                                        (errors?.email?.type === "pattern" &&
                                            localization.EMAIL_FORMAT)}
                                </FormHelperText>
                            </FormControl>
                        </Card>
                        {!errors?.email && (
                            <Typography
                                variant="caption"
                                sx={{
                                    color: theme.palette.white,
                                    textAlign: "center",
                                    fontWeight: 600,
                                }}
                            >
                                {localization.YOUR_EMAIL}
                            </Typography>
                        )}

                        {/* 2 number */}
                        {!phoneInputVisible && (
                            <CardNumber
                                border={errors?.phoneNumber ? "1px solid #FF2E00" : ""}
                                onClick={() => {
                                    setPhoneInputVisible(true);
                                }}
                            />
                        )}
                        {phoneInputVisible && (
                            <>
                                <FormControl>
                                    <Controller
                                        control={control}
                                        name="phoneNumber"
                                        rules={{required: true, pattern: phonePattern}}
                                        render={({field: {ref, ...field}}) => (
                                            <PhoneInput
                                                {...field}
                                                country="hr"
                                                inputExtraProps={{
                                                    ref,
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                specialLabel=""
                                                containerStyle={{
                                                    height: "3.8em",
                                                    width: "100%",
                                                    borderRadius: "25px",
                                                    border: errors?.phoneNumber
                                                        ? "1px solid #FF2E00"
                                                        : "",
                                                }}
                                                inputStyle={{
                                                    height: "100%",
                                                    width: "100%",
                                                    color: "#232955",
                                                    border: "0px solid white",
                                                    borderRadius: "25px",
                                                }}
                                            />
                                        )}
                                    />
                                    <FormHelperText
                                        error={Boolean(errors.phoneNumber)}
                                        sx={{
                                            mt: 0.5,
                                            ml: 4,
                                            "&.MuiFormHelperText-root": {
                                                color: errors?.phoneNumber
                                                    ? theme.palette.red
                                                    : theme.palette.blue.dark,
                                            },
                                        }}
                                    >
                                        {" "}
                                        {(errors?.phoneNumber?.type === "required" &&
                                                localization.PHONE_REQUIRED) ||
                                            (errors?.phoneNumber?.type === "pattern" &&
                                                localization.PHONE_FORMAT)}
                                    </FormHelperText>
                                </FormControl>
                            </>
                        )}
                    </Stack>

                    {/* 3 */}

                    <Card border={errors?.displayName ? "1px solid #FF2E00" : ""}>
                        <FormControl
                            sx={{
                                height: "100%",
                                mt: 1.5,
                                ml: 2,
                                width: "90%",
                            }}
                        >
                            <InputBase
                                placeholder={localization.DISPLAY_NAME}
                                type={"text"}
                                autoComplete="off"
                                name="displayName"
                                {...register("displayName", {
                                    required: true,
                                })}
                                error={Boolean(errors.displayName)}
                                label="Display name"
                            />
                            <FormHelperText
                                error={Boolean(errors.displayName)}
                                sx={{
                                    mt: 1.5,
                                    "&.MuiFormHelperText-root": {
                                        color: errors?.displayName
                                            ? theme.palette.red
                                            : theme.palette.blue.dark,
                                    },
                                }}
                            >
                                {" "}
                                {errors?.displayName?.type === "required" &&
                                    localization.NAME_REQUIRED}
                            </FormHelperText>
                        </FormControl>
                    </Card>
                    <PrivacyAndCookies control={control} errors={errors}/>
                </Stack>
                <PayButton isLoading={isLoading} type="submit">
                    {localization.CONTINUE.toUpperCase()}
                </PayButton>
            </Container>
        </form>
    );
};

export default User;
