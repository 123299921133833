import React, { useState, useEffect, useContext } from "react";
import { Typography, Container } from "@mui/material";
import { useTheme } from "@emotion/react";
import { localization } from "../localizationV2";
import Header from "../Header";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ApiContext } from "../../App";
import { useNavigate, useParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

const Payment = () => {
  const [clientSecret, setClientSecret] = useState("");
  const theme = useTheme();
  const API = useContext(ApiContext);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/");
    }

    fetch(`${API.host}/api/v1/payment/intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ rideId: id }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, [API, id, navigate]);

  const appearance = {
    theme: "flat",

    variables: {
      colorPrimary: "#232955",
      colorBackground: "#FFFFFF",
      colorText: "#232955",
      colorDanger: "#FF2E00",
      fontFamily: "Montserrat, sans-serif", 
      spacingUnit: "4.5px",
      borderRadius: "25px",
    },
  };
  const options = {
    clientSecret,
    appearance,
    loader: "auto",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>{localization.CHECKOUT.toLocaleUpperCase()}</Header>{" "}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Container maxWidth="md" sx={{ mt: 6 }}>
            <CheckoutForm id={id} />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                color: theme.palette.white,
                textAlign: "center",
                mt: 3,
              }}
            >
              {localization.PAYMENT_MESSAGE}
            </Typography>
          </Container>
        </Elements>
      )}
    </>
  );
};

export default Payment;
