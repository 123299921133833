import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyDkwat59SR4223rzSnG1aJt8ojQVhHCCWU",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "waterboattaxi-beta.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "waterboattaxi-beta",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "waterboattaxi-beta.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "76315562555",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:76315562555:web:fdca970804d8a298cec21e",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-SBMKFS9ED3",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {
  analytics
};
