import React, { useEffect, useState, useContext } from "react";
import { Container, Typography, Stack, Box } from "@mui/material";
import { localization } from "../localizationV2";
import CircularProgress from "@mui/material/CircularProgress";
import ContactSupport from "./ContactSupport";
import { ApiContext } from "../../App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Counter from "./Counter";
import HeaderHome from "../HeaderHome";

export const TotalBilled = ({ amount }) => {
  return (
    <Box
      sx={{
        background: "white",
        height: "3em",
        width: "100%",
        borderRadius: "25px",
      }}
    >
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="baseline"
        sx={{ height: "100%" }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#232955", fontWeight: 700, ml: 4, mt: 1 }}
        >
          {localization.TOTAL_BILLED.toLocaleUpperCase()}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "#232955", fontWeight: 700, ml: 4, mt: 1 }}
        >
          {amount}
        </Typography>
      </Stack>
    </Box>
  );
};

export const Spinner = ({ initValue }) => {
  return (
    <>
      <CircularProgress
        sx={{ color: "white", position: "relative" }}
        size={190}
        thickness={4}
      />
      <Counter initValue={initValue} />
    </>
  );
};

const LoadingScreen = () => {
  const theme = useTheme();
  const API = useContext(ApiContext);
  const [rideStatus, setRideStatus] = useState(null);
  const [rideDesignation, setRideDesignation] = useState(null);
  const [initValue, setInitValue] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const amount = state?.total?.amount.total;
  const currency = state?.total?.amount.currency;

  console.log(state);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!id) {
      navigate("/");
    }

    const interval = setInterval(async () => {
      const response = await fetch(`${API.host}/api/v1/ride/${id}`);
      const body = await response.json();
      setRideDesignation(body.rideDesignation);
      setInitValue(body.currentOffersCollectCounter);

      setRideStatus(body.status);
      if (body.status === "OFFERED") {
        clearInterval(interval);
        navigate(`/rideConfirmed`, {
          state: { ...body },
        });
      }
      if (
        body.status === "PAYMENT_PENDING" ||
        body.status === "PAYMENT_PROCESSING"
      ) {
        clearInterval(interval);
        navigate(`/payment/${id}`, {
          state: { ...body },
        });
      }
      if (body.status === "COMPLETED") {
        clearInterval(interval);
        navigate(`/completed`, {
          state: { ...body },
        });
      }
      if (body.status === "UNFULFILLED") {
        clearInterval(interval);

        navigate(`/unfulfilled`, {
          state: { ...body },
        });
      }
      if (body.status === "DECLINED") {
        clearInterval(interval);
      }
      if (body.status === "CANCELED_BY_USER") {
        clearInterval(interval);
      }

      if (
        body.status === "ACCEPTED" ||
        body.status === "ACTIVE" ||
        body.status === "IN_PICKUP" ||
        body.status === "IN_TRANSIT"
      ) {
        clearInterval(interval);
        navigate(`/active`, {
          state: { ...body },
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [id, navigate, API.host]);

  return (
    <>
      {!(rideStatus === "PENDING") && (
        <HeaderHome>{localization.CHECKOUT.toLocaleUpperCase()}</HeaderHome>
      )}{" "}
      <Container maxWidth="md">
        <Stack sx={{ mt: 3 }} spacing={4}>
          {rideStatus === "PENDING" && (
            <Stack spacing={7}>
              <Typography
                sx={{
                  textAlign: "center",
                  color: theme.palette.white,
                  fontWeight: 700,
                }}
              >
                {localization.CHECKOUT.toLocaleUpperCase()}
              </Typography>
              <Stack spacing={1.5}>
                <Typography sx={{ color: "#232955", fontWeight: 600 }}>
                  {localization.CONNECTING_CAPTAIN}
                </Typography>

                {amount && currency && (
                  <TotalBilled amount={amount + currency} />
                )}
                <Typography
                  variant="h6"
                  sx={{ color: "#232955", fontWeight: 800, ml: 4, mt: 1 }}
                >
                  {localization.DONT_CLOSE.toLocaleUpperCase()}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  {localization.WAIT}
                </Typography>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                {initValue && <Spinner initValue={initValue} />}{" "}
              </Stack>
            </Stack>
          )}
          {rideStatus === "DECLINED" && (
            <Typography sx={{ color: "#232955", fontWeight: 600 }}>
              You declined this ride, all funds were reverted.
            </Typography>
          )}
          {rideStatus === "CANCELED_BY_USER" && !state && (
            <Typography sx={{ color: "#232955", fontWeight: 600 }}>
              You canceled accepted ride, we will return 80% of the payed
              amount.
            </Typography>
          )}
          {rideStatus === "CANCELED_BY_USER" && state && (
            <Typography sx={{ color: "#232955", fontWeight: 600 }}>
              You canceled accepted ride, we will return 40% of the payed
              amount.
            </Typography>
          )}
          {rideStatus === "OFFERED" && <Spinner />}
          {rideStatus === "UNFULFILLED" && <Spinner />}
          {rideStatus === "UNFULFILLED" && <Spinner />}
          {rideStatus === "COMPLETED" && <Spinner />}
        </Stack>
        <Stack spacing={2} sx={{ mt: 6 }}>
          <ContactSupport />
          {rideDesignation && (
            <Typography
              sx={{
                color: "#232955",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {localization.RIDE_ID} {rideDesignation}
            </Typography>
          )}
        </Stack>
      </Container>
    </>
  );
};

export default LoadingScreen;
