import { Stack, Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { localization } from "../localizationV2";

const Passengers = ({ passengers }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.white,
        height: "2.5em",
        borderBottomLeftRadius: "25px",
        borderBottomRightRadius: "25px",
      }}
    >
      <Stack direction={"row"} spacing={1} sx={{ mt: 1, ml: 3 }}>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.blue.dark, fontWeight: 600 }}
        >
          {localization.PASSENGERS.toLocaleUpperCase()}:
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.grey, fontWeight: 600 }}
        >
          {passengers}{" "}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Passengers;
