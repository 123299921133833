import React from "react";
import {
  AppBar,
  ButtonBase,
  Container,
  Box,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import BackBtn from "./BackBtn";

const TitleButton = ({ children, onClick }) => {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ background: theme.palette.blue.light }}
    >
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex", ml: -2 }}>
            <BackBtn />
          </Box>
          <ButtonBase
            onClick={onClick}
            sx={{
              background: "#232955",
              width: "100%",
              borderRadius: "30px",
              height: "3.5em",
              ml: 1,
              mr: -2,
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {children}{" "}
            </Typography>
          </ButtonBase>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TitleButton;
