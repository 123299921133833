import React from "react";
import { Typography, ButtonBase } from "@mui/material";
import { useTheme } from "@emotion/react";
import CircularProgress from "@mui/material/CircularProgress";

const PayButton = ({ isLoading, disabled, id, type, children }) => {
  const theme = useTheme();

  return (
    <ButtonBase
      disabled={disabled}
      type={type}
      id={id}
      sx={{
        background: theme.palette.blue.dark,
        width: "100%",
        height: "4em",
        borderRadius: "25px",
        opacity: isLoading ? "0.7" : "1",
        mt: 2,
      }}
    >
      {!isLoading && (
        <Typography variant="h6" sx={{ color: "white", fontWeight: 700 }}>
          {children}{" "}
        </Typography>
      )}
      {isLoading && (
        <CircularProgress
          size={35}
          sx={{
            color: theme.palette.white,
          }}
        />
      )}
    </ButtonBase>
  );
};

export default PayButton;
