import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/system";
import { Container, Stack, Typography } from "@mui/material";
import ConfirmBtn from "../ConfirmBtn";
import { useNavigate, useLocation } from "react-router-dom";
import { localization } from "../localizationV2";
import Map from "../Map";
import Header from "../Header";
import InvalidLocation from "../InvalidLocation";
import WarningMessage from "./WarningMessage";

const DestinationMapNew = () => {
  const navigate = useNavigate();
  const [destination, setDestination] = useState(null);
  const [invalidLocation, setInvalidLocation] = useState(false);
  const [openInvalidLocation, setOpenInvalidLocation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const { state } = useLocation();

  const updateDestinationLocation = useCallback(
    ({ latitude, longitude, state }) => {
      setDestination({
        latitude,
        longitude,
        location: state?.destination?.title ?? "Custom Location",
      });
    },
    [setDestination]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.destinationMarker) {
      window.destinationMarker.setPosition({
        lat: state.destination.latitude,
        lng: state.destination.longitude,
      });
    }
  }, [state]);

  const defaultProps = {
    pickup: {
      lat: 43.17,
      lng: 16.45,
    },
    center: {
      lat: 43.17,
      lng: 16.45,
    },
    zoom: 12,
  };

  let loadMap = (map, maps) => {
    map.data.loadGeoJson("/geojson.json");
    map.data.setStyle({ visible: false });
    map.data.setStyle({
      fillColor: "transparent",
      strokeWeight: 0.1,
    });

    window.destinationMarker = new maps.Marker({
      position: {
        lat: state.destination.latitude,
        lng: state.destination.longitude,
      },
      map,
      draggable: true,
      icon: {
        url: "/imagesV2/locationRed.svg",
      },
    });

    if (state && state.destination) {
      window.destinationMarker.setPosition({
        lat: state.destination.latitude,
        lng: state.destination.longitude,
      });
    }

    const hvarCircle = new maps.Circle({
      strokeColor: "#FF2E00",
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: "#FF2E00",
      fillOpacity: 0.25,
      map,
      center: { lat: 43.172428, lng: 16.440816 },
      radius: 500,
    });

    window.google.maps.event.addListener(
      window.destinationMarker,
      "dragend",
      function (evt) {
        const latitude = parseFloat(evt.latLng.lat().toFixed(8));
        const longitude = parseFloat(evt.latLng.lng().toFixed(8));

        // detect which polygon from the geojson the marker is in
        map.data.forEach(function (feature) {
          if (feature.getGeometry().getType() === "Polygon") {
            // simplifying assumption, depends on data
            // just check first linear ring
            const poly = new maps.Polygon({
              path: feature.getGeometry().getAt(0).getArray(),
            });
            if (
              maps.geometry.poly.containsLocation(
                window.destinationMarker.getPosition(),
                poly
              )
            ) {
              console.log(feature);
              console.log("Location OK");
              setInvalidLocation(false);
              setOpenInvalidLocation(false);
            } else {
              setInvalidLocation(true);
              setOpenInvalidLocation(true);
              console.log("Invalid location");
            }
          }
        });

        if (
          maps.geometry.spherical.computeDistanceBetween(
            window.destinationMarker.getPosition(),
            hvarCircle.getCenter()
          ) <= hvarCircle.getRadius()
        ) {
          window.destinationMarker.setPosition({
            lat: 43.172428,
            lng: 16.440816,
          });
          updateDestinationLocation({
            latitude: 43.172428,
            longitude: 16.440816,
            location: "Hvar",
          });
          setOpenWarning(true);
        } else {
          updateDestinationLocation({ latitude, longitude });
          setOpenWarning(false);
        }
      }
    );
  };

  const mapComponent = (
    <Map
      height={"95%"}
      onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
      defaultProps={defaultProps}
    />
  );

  return (
    <Container maxWidth="md" disableGutters>
      <Box sx={{ height: "100vh" }}>
        <Stack>
          <Header>{localization.DROP_OF.toLocaleUpperCase()}</Header>
          <Box sx={{ background: "white", width: "100%", height: "2.5em" }}>
            <Typography
              sx={{
                fontWeight: 700,
                textAlign: "center",
                mt: 1,
                color: "#232955",
              }}
            >
              {localization.PIN_DEST}{" "}
            </Typography>
          </Box>
        </Stack>
        {mapComponent}
        <ConfirmBtn
          opacity={!invalidLocation ? "" : 0.2}
          disabled={!invalidLocation ? false : true}
          onClick={() => {
            navigate("/pickup", {
              state: {
                ...state,
                destination: {
                  latitude: window.destinationMarker.getPosition().lat(),
                  longitude: window.destinationMarker.getPosition().lng(),
                  title: state?.destination?.title,
                },
              },
            });
          }}
        >
          {localization.CONFIRM_DROP_OF.toLocaleUpperCase()}{" "}
        </ConfirmBtn>
      </Box>{" "}
      {openInvalidLocation === true && (
        <InvalidLocation
          openInvalidLocation={openInvalidLocation}
          setOpenInvalidLocation={setOpenInvalidLocation}
        />
      )}
      {openWarning && (
        <WarningMessage
          openWarning={openWarning}
          setOpenWarning={setOpenWarning}
        />
      )}
    </Container>
  );
};

export default DestinationMapNew;
