import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 92,
  height: 32,
  padding: 10,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(2px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(52px)",

      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8ED8F8",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#232955",
    width: 31,
    height: 31,
    "&:before": {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  },
}));

export default function CustomSwitch({ onChange, checked }) {
  return (
    <MaterialUISwitch onChange={onChange} checked={checked} name="switch" />
  );
}
