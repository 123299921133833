import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, InputBase } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { localization } from "../localizationV2";

const PickupNotes = ({ pickUpNotes, setPickUpNotes }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const [input, setInput] = useState(false);

  const pickUpNotesHandler = (e) => {
    setPickUpNotes(e.target.value);
  };

  return (
    <>
      {" "}
      <Box
        onClick={() => {
          setInput(true);
        }}
        sx={{
          background: "white",
          width: "100%",
          minHeight: "2.5em",
          borderTop: "1px solid #9A9A9A",
        }}
      >
        {!input && (
          <Typography
            variant={isMatch ? "body1" : "body2"}
            sx={{
              color: "#232955",
              fontWeight: 700,
              mt: 1.2,
              ml: 2,
            }}
          >
            {localization.PICKUP_NOTES.toLocaleUpperCase()}{" "}
          </Typography>
        )}{" "}
        {input && (
          <InputBase
            onChange={pickUpNotesHandler}
            value={pickUpNotes}
            placeholder={localization.PICKUP_NOTES}
            maxRows={2}
            multiline
            sx={{
              width: "90%",
              color: "#232955",
              mt: 0.5,
              ml: 2,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PickupNotes;
