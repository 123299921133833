export const localization = {
  AHOY_THERE: "Ahoy there, ",
  TAKE_YOU: "take you where?",
  WHERE_TO: "Where to?",
  CHOOSE_LOCAL: "Choose a local favourite:",
  INSURED: "All passengers are insured.",
  HIDDER_FEE: "No hidden fees.",
  ORDER: "Order a water taxi",
  DROP_OF: "Where do we drop you off?",
  PIN_DEST: "Slide pin on your destination ",
  CONFIRM_DROP_OF: "confirm destination",
  SEARCH_LOCAL: "or search favourite local spots",
  PICK_UP: "Where do we pick you up?",
  LOCATE: "Locate me!",
  LOCATE_PROGRESS: "Locating...",
  LOCATE_FAIL:
    "Your location services are turned off so we cannot send a boat to you. Turn them on in your settings > location services and try again.",
  OR_PIN_DEST: "Or slide pin on your location",
  ON_BOAT: "Pick me up from my boat",
  CONFIRM_PICKUP: "Confirm pickup location",
  TYPE: "TYPE OF BOAT",
  NAME: "BOAT NAME",
  COLOR: "BOAT COLOR",
  DONE: "Done",
  LAST_CHECK: "Last check of your route",
  PICK: "Pick up",
  DOPPED_PIN: "Dropped pin",
  DESTINATION: "DROP OFF",
  PASSENGERS: "Passengers",
  RETURN_TICKET: "Return ticket",
  PICKUP_NOTES: "Pickup notes (OPTIONAL)",
  TOTAL: "Total",
  CONFIRM_RIDE: "Confirm ride",
  EMAIL: "Enter email",
  EMAIL_REQUIRED: "Email is required",
  NAME_REQUIRED: "Name is required",
  EMAIL_FORMAT: "Email is badly formatted",
  PHONE: "Phone number",
  PHONE_FORMAT: "Phone number is invalid",
  PHONE_REQUIRED: "Phone number is required",
  PRIVACY: "I have read and understand the Privacy and Cookies Policy",
  CHECKOUT: "checkout",
  CONTINUE: "continue",
  CONNECTING_CAPTAIN: "We are contacting your captain",
  TOTAL_BILLED: "Total billed: ",
  DONT_CLOSE: "Do not close this screen!",
  WAIT: "Please wait until our captain confirms your pickup. It may take up to 1:30 min.",
  DECLINE: "DECLINE",
  DECLINED: "DECLINED",
  SUPPORT:
    "If you have any questions regarding your ride, please call our support at ",
  HOORAY: "Huzza!",
  RIDE_CONFIRMED: "Ride confirmed successfully!",
  BOAT: "Boat",
  CAPTAIN: "Your captain",
  REQUESTED_PICKUP_TIME: "Requested pickup time",
  SHORT_PICKUP_TIME: "Pickup time",
  PICKUP_TIME: "Estimated pickup time",
  YES: "Yes",
  NO: "No",
  RETURN: "Return",
  NOTES: "Pickup note",
  RIDE_CANCELED: "You cancelled your ride",
  RIDE_CANCELED2:
    "Tell us why you cancelled it so we can improve our service. ",
  DONT_SHARE: "I don’t want to",
  CANT_CONTACT: "Could not contact the driver",
  OTHER_TRANSPORT: "Found different transportation",
  ACCIDENTLY_CALLED: "Ordered by mistake",
  OTHER: "Other",
  DETAILS: "Enter details of your cancellation.",
  CONFIRM: "Confirm",
  CONFIRM_DECLINE:
    "Please confirm or decline this ride! Ride will be automatically confirmed after 3min.",
  SELECT_PAYMENT: "You’d like to pay with",
  PAY: "Pay",
  PAYMENT_MESSAGE:
    "Order details are sent to your email after the transaction is completed",
  BASE_AMOUNT: "Base amount",
  TAX: "Tax",
  TAX_AMOUNT: "Tax amount",
  DISPLAY_NAME: "Display name",
  ACTIVE_RIDE: "Active ride",
  IN_PICKUP: "In pickup",
  IN_TRANSIT: "IN transit",
  ACTIVE: "Ride is active, we will let you know when skipper start this ride!",
  PICKUP: "Skipper is currently coming for you!",
  TRANSIT: "Skipper picked you up! Have a nice trip!",
  COMPLETED: "Completed ride",
  NEXT: "Next",
  YOUR_TRIP: "How was your trip?",
  GIVE_RATING: "Give us a rating to enhance next ride experience",
  EXPERIENCE: "Tell us your experience about ride with us!",
  SEND: "Send",
  SKIPPER: "Skipper",
  RIDE: "ride",
  EXPIRED: "This ride is expired!",
  SHEDULE_NEW: "Schedule a new ride",
  SKIPPER_PICKED: "Skipper picked you up at",
  COMPLETED_RIDE: "Ride is completed at",
  CANCEL: "Cancel ride",
  RIDE_ID: "Ride id:",
  SKIPPER_INPICKUP: "Skipper in pickup at",
  CHOOSE_ANOTHER: "Or choose other payment method",
  SECURE_PAYMENTS: "Secure online payments.",
  NO_RESULT: "No result",
  YOUR_EMAIL: "Your email will be used for invoices only!",
  CANC: "Cancel",
  RED_CIRCLE: "PICK UP Possible within the RED CIRCLE ONLY",
  INVALID_LOC:
    "This location is outside our driving area. If you still want to go to this location, please contact our support on:",
  WHO_WE_ARE: "Who we are?",
  FAQ_TEXT_1:
    "We are not joking when it comes to our work. We love every nook and cranny of the coastline, so we can find the right spot for you, even if you don't know what exactly you want. A secluded beach, the place where the party never stops, perfect hangover breakfast or a romantic sunset dinner, before you retire to your vessel. ",
  FAQ_TEXT_2:
    " We can take you from one boat on an island, to another island. Tell us where you want to go. We'll give the journey dreamlike quality. Or just shut up and drive, if that is how you feel.",
  FAQ_TEXT_3: "Hold on, it might be choppy today. ",
  FAQ_TEXT_4:
    "Reliable - if we say we are picking you up, we are picking you up! Definitely!",
  FAQ_TEXT_5: "Professional - our level of service speaks volumes.",
  FAQ_TEXT_6: "English-speaking - with an accent, but still pretty good. ",
  FAQ_TEXT_7: "No hidden fees  - you pay what the app shows. That's it. ",
  FAQ_TEXT_8:
    "Secure online payments - no need for cash, but you will need a charged phone.",
  FAQ_TEXT_9:
    "Experienced local skippers - riding these waters for 10+ years. ",
  FAQ_TEXT_10: "All passengers and their luggage are insured. ",
  TOP_7: "The top 7 reasons to ride with Ahoy.",
  OH_NO: "Oh no!",
  RUSH_HOUR:
    "Rush hour on the waves! All our boats are full. Try again in a minute. Or five.",
  BACK_TO_HOME: "back to home",
  REFUND: "100% refund issued. ",
};
