import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import {useTheme} from "@emotion/react";
import {Controller, useForm} from "react-hook-form";
import moment from "moment/moment";
import CustomSwitch from "../CustomSwitch";
import {EditBtn} from "./ChosenLocation";
import TimePickerDialog from "../Home/TimePickerDialog";
import ButtonBase from "@mui/material/ButtonBase";

const PickupTime = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.up("sm"));

    const time = sessionStorage.getItem('datetime')
    const timeFormat = JSON.parse(time)


    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const {control} = useForm();

    const isPickedTime = !!(timeFormat && timeFormat.datetime != null)

    console.log(isPickedTime)

    const [timePickerVisible, setTimePickerVisible] = useState(isPickedTime)
    const [checked, setChecked] = useState(!isPickedTime)


    return (

        <Box
            sx={{
                background: "white",
                minHeight: "2.5em",
                borderTop: "1px solid #9A9A9A",
                display: 'flex', flexDirection: 'column', gap: 2, p: 2
            }}
        >

            {timePickerVisible &&
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 1.2,
                        }}>
                        <Typography
                            variant={isMatch ? "body1" : "body2"}
                            sx={{
                                color: "#232955",
                                fontWeight: 700,

                            }}
                        >
                            PICKUP TIME AND DATE:
                        </Typography>
                        {!isPickedTime && <ButtonBase
                            onClick={handleClickOpen}
                            sx={{
                                height: "2em",
                                width: isMatch ? "12em" : "10em",
                                background: "#8ED8F8",
                                borderRadius: "20px",
                            }}
                        >
                            <Typography variant="body2" sx={{color: "white", fontWeight: 700}}>
                                CHOOSE TIME
                            </Typography>
                        </ButtonBase>}

                    </Box>

                    {isPickedTime && <Box
                        sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
                        <Typography
                            variant={isMatch ? "body1" : "body2"}
                            sx={{color: "#9A9A9A", fontWeight: 700,}}
                        >{moment(timeFormat.datetime).format('DD.MM.YYYY, HH:mm.')}</Typography>
                        <EditBtn onClick={handleClickOpen}></EditBtn>
                    </Box>}

                    <TimePickerDialog open={open} handleClose={handleClose}/>
                </Box>}

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{mb: 2, mt: !timePickerVisible ? 2 : 0}}
            >
                <Typography
                    variant={isMatch ? "body1" : "body2"}
                    sx={{color: "#232955", fontWeight: 700}}
                >
                    PICK ME UP NOW!
                </Typography>
                <Controller
                    name="ride-now"
                    control={control}
                    render={({field}) => (
                        <CustomSwitch
                            onChange={(e) => {
                                field.onChange(e);
                                if (!checked) {
                                    setTimePickerVisible(false);
                                    setChecked(true);
                                    sessionStorage.removeItem('datetime')
                                } else {
                                    setTimePickerVisible(true);
                                    setChecked(false);
                                }
                            }}
                            checked={checked}
                        />
                    )}
                />
            </Stack>
            <Typography
                variant={isMatch ? "body1" : "body2"}
                sx={{color: "#9A9A9A", fontWeight: 700, mt: -2,}}
            >
                (YOU CAN SCHEDULE YOUR PICKUP, OR CHOOSE TO BE PICKED UP NOW.)
            </Typography>
        </Box>
    )
}

export default PickupTime