import React, {useState, useEffect, useRef} from "react";
import {Stack, Box} from "@mui/material";
import PopularLocations from "./PopularLocations";
import SafeRide from "./SafeRide";
import WhereToBtn from "./WhereToBtn";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@emotion/react";
import Header from "./Header";
import {Container} from "@mui/material";
import {Fade} from "@mui/material";
import {SwitchTransition} from "react-transition-group";


const HomeV2 = ({locations}) => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
    const nodeRef = useRef(null);

    const [activeLocation, setActiveLocation] = useState(locations[0].name);
    const [locationIndex, setLocationIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const backgroundImage = locations[locationIndex].backgroundImage.url;

    return (
        <Container
            maxWidth={isMatch ? "md" : false}
            disableGutters
            sx={{
                pb: 2, position: 'relative',

            }}
        >


            <Stack spacing={isMatch ? -25 : -15}>
                <Stack>
                    <Header/>
                    <WhereToBtn type={"submit"}/>

                </Stack>
                <SwitchTransition>
                    <Fade timeout={{enter: 1300, exit: 50}} key={backgroundImage}>
                        <Box
                            ref={nodeRef}
                            sx={{
                                backgroundImage:
                                    activeLocation &&
                                    `linear-gradient(0deg, rgba(142, 216, 248, 1) 0%, rgba(255, 255, 255, 0) 21%, rgba(255, 255, 255, 0) 69%, rgba(142, 216, 248, 1) 100%),url(${backgroundImage})`,
                                height: isMatch ? "50em" : "30em",
                                backgroundSize: "cover",
                                zIndex: 1,
                            }}
                        ></Box>
                    </Fade>
                </SwitchTransition>
            </Stack>

            <PopularLocations
                locations={locations}
                activeLocation={activeLocation}
                setActiveLocation={setActiveLocation}
                setLocationIndex={setLocationIndex}
            />

            {<SafeRide/>}
        </Container>
    );
};

export default HomeV2;
