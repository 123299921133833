import React from "react";
import { Typography, Stack, ButtonBase } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { localization } from "../localizationV2";

const Passengers = ({ setCount, count, maxSeats }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        variant={isMatch ? "body1" : "body2"}
        sx={{ color: "#232955", fontWeight: "700" }}
      >
        {localization.PASSENGERS.toLocaleUpperCase()}: {count}
      </Typography>
      <Stack direction="row" spacing={0.2}>
        <ButtonBase
          onClick={() => {
            setCount(Math.max(count - 1, 1));
          }}
          sx={{
            height: isMatch ? "2.4em" : "2em",
            width: isMatch ? "7.6em" : "4.7em",
            background: "#232955",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          }}
        >
          <RemoveIcon sx={{ color: "white" }} />
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            setCount(Math.min(count + 1, maxSeats));
          }}
          sx={{
            height: isMatch ? "2.4em" : "2em",
            width: isMatch ? "7.6em" : "4.7em",
            background: "#232955",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <AddIcon sx={{ color: "white" }} />
        </ButtonBase>
      </Stack>
    </Stack>
  );
};

export default Passengers;
