import React from "react";
import { ButtonBase, Container, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

function ConfirmBtn({
  children,
  onClick,
  type,
  boatSwitch,
  opacity,
  disabled,
}) {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        top: "auto",
        bottom: 0,
        background: theme.palette.blue.light,
      }}
    >
      <Container maxWidth="md" disableGutters>
        {boatSwitch && (
          <Toolbar
            variant="string"
            sx={{ background: theme.palette.white }}
            disableGutters
          >
            {boatSwitch}
          </Toolbar>
        )}
        <Toolbar sx={{ background: theme.palette.blue.dark }} disableGutters>
          <ButtonBase
            disabled={disabled}
            onClick={onClick}
            type={type}
            sx={{
              width: "100%",
              height: "100%",
              opacity: opacity,
            }}
          >
            <Typography variant="h6" sx={{ color: "white", fontWeight: 600 }}>
              {children}
            </Typography>
          </ButtonBase>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ConfirmBtn;
