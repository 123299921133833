import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  ButtonBase,
  Stack,
  Typography,
  Box,
  InputBase,
  Divider,
  List,
  Container,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TitleButton from "../TitleButton";
import { localization } from "../localizationV2";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { ApiContext } from "../../App";
import { debounce } from "lodash";

export const SearchBox = ({ onClick }) => {
  const theme = useTheme();
  return (
    <Box onClick={onClick} sx={{ width: "100%", height: "2.3em" }}>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.blue.dark,
          fontWeight: 600,
          mt: 1.3,
          ml: 2.5,
        }}
      >
        {localization.SEARCH_LOCAL}{" "}
      </Typography>
    </Box>
  );
};

const PopularLocationList = () => {
  const theme = useTheme();
  const API = useContext(ApiContext);

  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [locations, setLocations] = useState("");
  const { state } = useLocation();

  const getLocations = useCallback(async () => {
    const response = await fetch(
      `${API.host}/api/v1/popularlocation/search?query=${query}`
    );
    const data = await response.json();
    setLocations(data);
  }, [API.host, query]);

  const handleSearch = debounce((e) => {
    setQuery(e.target.value);
  }, 500);

  useEffect(() => {
    getLocations().catch((e) => console.error(e));
  }, [getLocations, query]);

  const emptySearch = locations.length === 0;

  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <Stack spacing={1.5}>
        <TitleButton
          onClick={() => {
            navigate("/destination", {
              state: {
                ...state,
                destination: { latitude: 43.172428, longitude: 16.440816 },
              },
            });
          }}
        >
          {localization.PIN_DEST}{" "}
        </TitleButton>
        <Box
          sx={{
            minHeight: "10em",
            maxHeight: isMatch ? "55em" : "41em",
            width: "100%",
            background: theme.palette.white,
            borderRadius: "25px",
            pb: 1,
          }}
        >
          {!searchVisible && (
            <SearchBox
              onClick={() => {
                setSearchVisible(true);
              }}
            />
          )}
          {searchVisible && (
            <InputBase
              type="search"
              placeholder={localization.SEARCH_LOCAL}
              sx={{
                mt: 1,
                mb: 1,
                ml: 2,
                width: "90%",
                color: theme.palette.blue.dark,
              }}
              onChange={handleSearch}
            />
          )}
          <Divider />
          {emptySearch && (
            <Typography
              sx={{
                mt: 2,
                textAlign: "center",
                color: theme.palette.blue.dark,
                fontWeight: 600,
              }}
            >
              {localization.NO_RESULT}{" "}
            </Typography>
          )}
          {locations && (
            <List
              sx={{
                width: "100%",
                overflow: "auto",
                maxHeight: isMatch ? "46.3em" : "34.7em",
              }}
            >
              {locations.map(
                (
                  { image, name, location, shortDescription, gps, type },
                  index
                ) => {
                  const latitude = gps.latitude;
                  const longitude = gps.longitude;
                  const title = name;

                  return (
                    <Box key={index}>
                      <Stack
                        sx={{ m: 1.3, mt: 0.5 }}
                        direction="row"
                        spacing={2}
                      >
                        <img
                          src={image.url}
                          alt=""
                          style={{
                            height: "11em",
                            width: "9em",
                            borderRadius: "25px",
                          }}
                        />
                        <Stack
                          sx={{ width: "100%", height: "100%" }}
                          spacing={0.5}
                        >
                          <Stack
                            sx={{ width: "100%" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Stack>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: theme.palette.blue.dark,
                                  fontWeight: 800,
                                  letterSpacing: 0.6,
                                  lineHeight: "115%",
                                }}
                              >
                                {name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: theme.palette.blue.dark,
                                  fontWeight: 800,
                                  letterSpacing: 0.6,
                                  lineHeight: "115%",
                                }}
                              >
                                {location}
                              </Typography>
                              <Typography
                                sx={{
                                  color: theme.palette.blue.dark,
                                  fontWeight: 800,
                                  fontSize: 11,
                                  letterSpacing: 0.7,
                                  lineHeight: "170%",
                                }}
                              >
                                {type.toLocaleUpperCase()}
                              </Typography>
                            </Stack>
                            <ButtonBase
                              onClick={() => {
                                navigate("/pickup", {
                                  state: {
                                    ...state,
                                    destination: {
                                      title,
                                      latitude,
                                      longitude,
                                    },
                                  },
                                });

                                console.log(name, latitude, longitude);
                              }}
                              sx={{
                                height: "3.5em",
                                width: "3.5em",
                                borderRadius: "40px",
                                backgroundColor: theme.palette.blue.dark,
                              }}
                            >
                              <img src="imagesV2/ArrowSmall.svg" alt="" />
                            </ButtonBase>
                          </Stack>
                          <Typography
                            sx={{
                              color: theme.palette.textGrey,
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "121%",
                            }}
                          >
                            {shortDescription}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  );
                }
              )}
            </List>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

export default PopularLocationList;
