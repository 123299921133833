import React, { useContext } from "react";
import { Dialog, Typography, Stack, Link } from "@mui/material";
import { useTheme } from "@mui/material";
import { localization } from "./localizationV2";
import { DataContext } from "../App";

const InvalidLocation = ({ openInvalidLocation, setOpenInvalidLocation }) => {
  const theme = useTheme();
  const content = useContext(DataContext);

  const handleClose = () => {
    setOpenInvalidLocation(false);
  };

  return (
    <Dialog
      open={openInvalidLocation}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          borderRadius: "25px",
          background: theme.palette.red,
          height: "10em",
          width: "22em",
        },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={-1}
      >
        <Typography
          sx={{
            color: theme.palette.white,
            m: 2,
            p: 2,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {localization.INVALID_LOC.toLocaleUpperCase()}
        </Typography>

        <Link
          sx={{
            color: "white",
            textDecoration: "none",
            fontWeight: 700,
            fontSize: 19,
          }}
          href={`tel:${content.phone}`}
        >
          {content.phone}
        </Link>
      </Stack>
    </Dialog>
  );
};

export default InvalidLocation;
