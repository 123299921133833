import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {Controller, useForm} from "react-hook-form";
import ButtonBase from "@mui/material/ButtonBase";
import React from "react";
import {styled} from "@mui/material/styles";
import moment from "moment/moment";
import {useLocation, useNavigate} from "react-router-dom";


export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgb(255, 255, 255, 0.5);
  }
`;

const TimePickerDialog = ({open, handleClose}) => {

    const navigate = useNavigate()
    const timeDate = sessionStorage.getItem('datetime')
    const time = JSON.parse(timeDate)

    const initValues = time ? moment(time.datetime) : null

    const location = useLocation();

    const {
        handleSubmit, control,
    } = useForm({defaultValues: {datetime: initValues}});

    let maxAllowedDateTimeForSchedule = moment().add(5, "days");

    let plus45 = moment().add(46, 'minutes')

    const onSubmit = (data) => {
        const time = JSON.stringify(data)
        sessionStorage.setItem('datetime', time)
        handleClose();
        if (location.pathname === '/') {
            navigate('/search')
        }
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    color: "#232955",
                    borderRadius: "25px",
                    width: "100%",
                },
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle sx={{color: "#232955"}}>
                    Schedule a future ride
                </DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                            name="datetime"
                            control={control}
                            render={({field: {ref, ...rest}}) => (
                                <DateTimePicker
                                    slotProps={{textField: {variant: "standard"}}}
                                    sx={{
                                        marginTop: "1rem",
                                        marginBottom: "0.5rem",
                                        width: "100%",
                                        color: "#232955",
                                    }}
                                    label="Pick date and time"
                                    format="DD/MM/YYYY HH:mm"
                                    disablePast
                                    minutesStep={15}
                                    ampm={false}
                                    maxDate={maxAllowedDateTimeForSchedule}
                                    minDateTime={plus45}
                                    {...rest}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <ButtonBase
                        sx={{
                            height: "2rem",
                            width: "5.5rem",
                            color: "blue.light",
                            fontSize: "0.9rem",
                            border: `1px solid #8ED8F8`,
                            fontWeight: 600,
                            borderRadius: "24px",
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </ButtonBase>
                    <ButtonBase
                        sx={{
                            height: "2rem",
                            width: "5.5rem",
                            color: "#ffffff",
                            fontSize: "0.9rem",
                            background: `#8ED8F8`,
                            fontWeight: 600,
                            borderRadius: "24px",
                        }}
                        type="submit"
                    >
                        Schedule
                    </ButtonBase>
                </DialogActions>
            </form>
        </StyledDialog>
    )
}

export default TimePickerDialog