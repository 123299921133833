import { ButtonBase, Typography } from "@mui/material";
import React from "react";

const DeclineBtn = ({ onClick, children }) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        background: "#FF2E00",
        width: "100%",
        height: "4em",
        borderRadius: "25px",
      }}
    >
      <Typography variant="h6" sx={{ color: "white", fontWeight: 700 }}>
        {children}
      </Typography>{" "}
    </ButtonBase>
  );
};

export default DeclineBtn;
