import React, {useEffect, useState} from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import {Typography} from "@mui/material";
import PayButton from "./PayButton";
import {localization} from "../localizationV2";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({id}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        elements.getElement("payment").on("ready", () => {
            setIsReady(true);
        });

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, elements]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const {error, paymentIntent} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_DOMAIN}/ride/${id}`,
            },
            redirect: 'if_required'
        });

        if(!error && paymentIntent) {
            const args = {
                dataLayer: {
                    event: 'gtmt.purchase',
                    rideId: id,
                    value: paymentIntent.amount / 100.0,
                    currency: paymentIntent.currency,
                }
            };
            TagManager.dataLayer(args);
            navigate(`/ride/${id}`);
        }

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {" "}
            <PaymentElement id="payment-element"/>
            {isReady && (
                <PayButton
                    isLoading={isLoading}
                    id="submit"
                    type="submit"
                    disabled={isLoading || !stripe || !elements}
                >
                    {localization.PAY.toUpperCase()}
                </PayButton>
            )}
            {message && (
                <Typography
                    sx={{color: "#FF2E00", fontWeight: 700, textAlign: "center"}}
                    id="payment-message"
                >
                    {message}
                </Typography>
            )}
        </form>
    );
};

export default CheckoutForm;
