import React from "react";
import { Box, Stack, InputBase, ButtonBase, Typography } from "@mui/material";
import { Backdrop } from "@mui/material";
import { Controller } from "react-hook-form";
import { localization } from "../localizationV2";
import { useTheme } from "@mui/material";

const CustomInput = ({ placeholder, onChange, name }) => {
  return (
    <Box
      sx={{
        height: "2.8em",
        width: "90%",
        borderRadius: "20px",
        border: "1.5px solid #232955",
      }}
    >
      <InputBase
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        type="text"
        sx={{
          fontSize: 14,
          fontWeight: 600,
          m: 1,
          ml: 3,
          width: "90%",
          color: "#232955",
        }}
      ></InputBase>
    </Box>
  );
};

const CustomButton = ({ border, onClick, children }) => {
  const theme = useTheme();
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        width: "30%",
        height: "3em",
        borderRadius: "25px",
        border: border,
      }}
    >
      {" "}
      <Typography
        variant="body2"
        sx={{ color: theme.palette.blue.dark, fontWeight: 600 }}
      >
        {children}{" "}
      </Typography>
    </ButtonBase>
  );
};

const BoatDialog = ({ setOpen, open, control, setChecked }) => {
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setChecked(false);
  };

  return (
    <Backdrop open={open} sx={{ background: "rgb(142,216,248, 0.7)" }}>
      <Box
        sx={{
          height: "14em",
          width: "92%",
          background: "white",
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ height: "100%" }}
        >
          <Controller
            control={control}
            name="boatType"
            render={({ field }) => (
              <CustomInput
                onChange={(e) => {
                  field.onChange(e);
                }}
                selected={field.value}
                placeholder={localization.TYPE}
              />
            )}
          />
          <Controller
            control={control}
            name="boatName"
            render={({ field }) => (
              <CustomInput
                onChange={(e) => field.onChange(e)}
                selected={field.value}
                placeholder={localization.NAME}
              />
            )}
          />
          <Controller
            control={control}
            name="boatColor"
            render={({ field }) => (
              <CustomInput
                onChange={(e) => field.onChange(e)}
                selected={field.value}
                placeholder={localization.COLOR}
              />
            )}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ width: "90%" }}
          >
            <CustomButton onClick={handleCancel}>
              {" "}
              {localization.CANC.toLocaleUpperCase()}
            </CustomButton>
            <CustomButton
              onClick={handleClose}
              border={`1px solid ${theme.palette.blue.dark}`}
            >
              {" "}
              {localization.DONE.toLocaleUpperCase()}
            </CustomButton>
          </Stack>
        </Stack>
      </Box>
    </Backdrop>
  );
};

export default BoatDialog;
