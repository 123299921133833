import React from "react";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const arrow = "/imagesV2/ArrowSmallBlack.svg";

const BackBtn = ({ ml, mr, mt }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <ButtonBase
      onClick={() => navigate(-1, { state: { ...state } })}
      sx={{
        background: "white",
        borderRadius: "40px",
        height: "3.5em",
        width: "3.5em",
        ml: ml,
        mr: mr,
        mt: mt,
      }}
    >
      <img src={arrow} alt="" style={{ width: "2em" }} />
    </ButtonBase>
  );
};

export default BackBtn;
