import React, {useState, useEffect, useContext, useCallback} from "react";
import {Box} from "@mui/system";
import {Container, Stack} from "@mui/material";
import ChosenLocation from "./ChosenLocation";
import Passengers from "./Passengers";
import {ApiContext, DataContext} from "../../App";
import {useFetch} from "use-http";
import PickupNotes from "./PickupNotes";
import {useTheme} from "@emotion/react";
import {useMediaQuery} from "@mui/material";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import Total from "./Total";
import Header from "../Header";
import {localization} from "../localizationV2";
import ContinueBtn from "../ContinueBtn";
import TagManager from 'react-gtm-module';
import PickupTime from "./PickupTime";

const ConfirmationScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {state} = useLocation();
    const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
    const API = useContext(ApiContext);
    const config = useContext(DataContext);
    const {get, response} = useFetch(API.host);
    const [count, setCount] = useState(1);
    const [price, setPrice] = useState("");
    const [pickUpNotes, setPickUpNotes] = useState("");
    const [mapView, setMapView] = useState();

    const tagManagerArgs = {
        dataLayer: {
            event: 'gtmt.confirm_screen',
            ...state
        }
    }

    const {post: getMap, response: mapResponse} = useFetch(API.host);

    const timeDate = sessionStorage.getItem('datetime')
    let scheduleRideAt;
    if (timeDate) {
        scheduleRideAt = JSON.parse(timeDate).datetime;
    }
    console.log(timeDate)

    const getPrice = useCallback(async () => {
        const price = await get(`/api/v1/price-estimate?numberOfTickets=${count}`);
        if (response.ok) setPrice(price);
        const args = {
            dataLayer: {
                event: 'gtmt.change_tickets',
                numberOfTickets: count
            }
        };
        TagManager.dataLayer(args);
    }, [get, response, count]);

    const getMapView = useCallback(
        async (pickup, destination) => {
            const map = await getMap(`/api/v1/mapview`, {pickup, destination});
            if (mapResponse.ok) setMapView(map);
        },
        [getMap, mapResponse]
    );

    useEffect(() => {
        if (!state) {
            navigate("/", {});
        }
        console.log(state);
        window.scrollTo(0, 0);
        getPrice().catch((e) => console.error(e));
        getMapView(state.pickup, state.destination);
    }, [getPrice, state, navigate, getMapView]);


    return (
        <>
            <Header>{localization.LAST_CHECK.toLocaleUpperCase()}</Header>
            <Container maxWidth="md" sx={{pt: 1, pb: 2}}>
                <Stack spacing={4}>
                    <Box
                        sx={{
                            background: "white",
                            width: "100%",
                            minHeight: "16em",
                            borderRadius: "30px",
                            zIndex: 99,
                        }}
                    >
                        <ChosenLocation state={state}/>
                        <Stack sx={{m: 2}} spacing={2}>
                            <Passengers count={count} setCount={setCount} maxSeats={config.maxNumberOfSeats}/>
                            {/* <ReturnTicket /> */}
                        </Stack>

                        <PickupTime/>
                        <PickupNotes
                            pickUpNotes={pickUpNotes}
                            setPickUpNotes={setPickUpNotes}
                        />{" "}
                        <Total
                            baseAmount={price.baseAmount}
                            currency={price.currency}
                            tax={price.tax}
                            taxAmount={price.taxAmount}
                            total={price.total}
                        />
                    </Box>

                    {mapView && (
                        <img
                            src={mapView}
                            alt=""
                            style={{
                                zIndex: 1,
                                width: "100%",
                                height: isMatch ? "auto" : "20em",
                            }}
                        />
                    )}

                    <ContinueBtn
                        onClick={() => {
                            TagManager.dataLayer(tagManagerArgs);
                            navigate("/user", {
                                state: {
                                    ...state,
                                    pickup: {
                                        ...state.pickup,
                                        pickupNote: {
                                            note: pickUpNotes,
                                            boatInfo: {
                                                name: state?.pickup?.pickupInfo.boatName,
                                                type: state?.pickup?.pickupInfo.boatType,
                                                color: state?.pickup?.pickupInfo.boatColor,
                                            },
                                        },
                                    },
                                    paymentDetails: {numberOfTickets: count, amount: price, scheduleRideAt},
                                },
                            });
                        }}
                    >
                        {localization.CONFIRM_RIDE.toLocaleUpperCase()}
                    </ContinueBtn>
                </Stack>
            </Container>
        </>
    );
};

export default ConfirmationScreen;
