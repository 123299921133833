import {Container, Typography, Stack, Box} from "@mui/material";
import React, {useState, useEffect} from "react";
import {localization} from "../localizationV2";
import {useTheme} from "@emotion/react";
import Map from "../Map";
import ContinueBtn from "../ContinueBtn";
import {useNavigate} from "react-router-dom";
import ChoosenLocations from "../ChoosenLocations";
import {useLocation} from "react-router-dom";
import moment from "moment";
import HeaderHome from "../HeaderHome";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebaseSetup";

const Locations = ({destination, pickup}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                background: "white",
                height: "7em",
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
                borderBottom: `1px solid ${theme.palette.grey}`,
            }}
        >
            <ChoosenLocations
                m={1}
                destinationLocation={destination}
                pickUpLocation={pickup}
            />
        </Box>
    );
};

const Wrapper = ({
                     q,
                     a,
                     borderBottomLeftRadius,
                     borderBottomRightRadius,
                     borderBottom,
                 }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                background: "white",
                height: "2.5em",
                borderBottom: borderBottom,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius,
            }}
        >
            <Stack direction={"row"} spacing={1} sx={{mt: 1, ml: 2}}>
                <Typography
                    variant="body2"
                    sx={{color: theme.palette.blue.dark, fontWeight: 600}}
                >
                    {q}:
                </Typography>
                <Typography
                    variant="body2"
                    sx={{color: theme.palette.grey, fontWeight: 600}}
                >
                    {a}
                </Typography>
            </Stack>
        </Box>
    );
};

const CompletedRide = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [completeRideData, setCompleteRideData] = useState(null);

    const {state} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!state) {
            navigate("/", {});
        }
        if (state && state.id && state.status !== "COMPLETED") {
            navigate(`/ride/${state.id}`, {});
        }
        setCompleteRideData(state);
        logEvent(analytics, "completed_ride", { id: state.id });
    }, [state, navigate]);

    console.log(completeRideData);

    const dest = completeRideData?.destination.title ?? "Dropped pin";
    const pick = completeRideData?.pickup.title ?? "Dropped pin";
    const pickupTime = moment(completeRideData?.skipperInPickupAt).format("LT");
    const completedRide = moment(completeRideData?.completedAt).format("LT");

    let loadMap = (map, maps) => {
        window.pickupMarker = new maps.Marker({
            position: {lat: state.pickup.latitude, lng: state.pickup.longitude},
            map,
            draggable: false,
            icon: {
                url: "/imagesV2/locationBlue.svg",
            },
        });

        window.destinationMarker = new maps.Marker({
            map,
            position: {
                lat: state.destination.latitude,
                lng: state.destination.longitude,
            },
            draggable: false,
            icon: {
                url: "/imagesV2/locationRed.svg",
            },
        });
    };

    const defaultProps = {
        center: {
            lat: state.destination.latitude,
            lng: state.destination.longitude,
        },
        zoom: 9,
    };

    const mapComponent = (
        <Map
            onGoogleApiLoaded={({map, maps}) => loadMap(map, maps)}
            defaultProps={defaultProps}
            height={350}
        />
    );

    return (
        <>
            <HeaderHome> {localization.COMPLETED.toLocaleUpperCase()} </HeaderHome>
            {completeRideData && (
                <Container maxWidth="md">
                    <Stack sx={{mt: 4}} spacing={2.5}>
                        <Stack>
                            <Locations destination={dest} pickup={pick}/>

                            <Wrapper
                                q={localization.SKIPPER_INPICKUP}
                                a={pickupTime}
                                borderBottom={`1px solid ${theme.palette.grey}`}
                            />

                            <Wrapper
                                q={localization.COMPLETED_RIDE}
                                a={completedRide}
                                borderBottomLeftRadius={"25px"}
                                borderBottomRightRadius={"25px"}
                            />
                        </Stack>
                        {mapComponent}
                    </Stack>
                    <ContinueBtn
                        pt={3}
                        pb={1}
                        onClick={() => {
                            navigate("/rate", {
                                state: {
                                    skipperInfo: completeRideData.skipper,
                                    boatInfo: completeRideData.boat,
                                    rideId: completeRideData.id
                                },
                            });
                        }}
                    >
                        {localization.NEXT.toLocaleUpperCase()}{" "}
                    </ContinueBtn>
                </Container>
            )}
        </>
    );
};

export default CompletedRide;
