import React, { useState } from "react";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { ExpandMore } from "../ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import { localization } from "../localizationV2";
import { useTheme } from "@emotion/react";

const Notes = ({ notes }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Box
        sx={{
          background: "white",
          height: "2.5em",
          width: "100%",
          borderBottom: expanded ? "" : `1px solid ${theme.palette.grey}`,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "2.5em", width: "91%", ml: 3 }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#232955", fontWeight: 600 }}
          >
            {localization.NOTES.toLocaleUpperCase()}:
          </Typography>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon
              sx={{
                fontSize: 45,
                color: "#232955",
                ":hover": { color: theme.palette.blue.light },
              }}
            />
          </ExpandMore>
        </Stack>
      </Box>
      <Collapse in={expanded} timeout={20} unmountOnExit>
        <Box
          sx={{
            background: "white",
            height: "2em",
            borderBottom: expanded ? `1px solid ${theme.palette.grey}` : "",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#9A9A9A", fontWeight: 600, ml: 3 }}
          >
            {notes}{" "}
          </Typography>
        </Box>
      </Collapse>
    </>
  );
};

export default Notes;
