import React from "react";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { useTheme } from "@emotion/react";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FFFFFF",
  },
  "& .MuiRating-iconHover": {
    color: "#FFFFFF",
  },
});

const CustomizedRating = ({ onChange }) => {
  const theme = useTheme();
  return (
    <StyledRating
      defaultValue={0}
      icon={<StarRoundedIcon sx={{ fontSize: 40 }} />}
      emptyIcon={
        <StarBorderRoundedIcon
          sx={{ fontSize: 40, color: theme.palette.white }}
        />
      }
      onChange={onChange}
    />
  );
};

export default CustomizedRating;
