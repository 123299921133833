import React, { useState } from "react";
import { Box, Stack, Typography, Container } from "@mui/material";
import CustomSwitch from "../CustomSwitch";
import BoatDialog from "./BoatDialog";
import { Controller } from "react-hook-form";
import { localization } from "../localizationV2";

const Boat = ({ open, setOpen, control }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          height: "2.5em",
          background: "white",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "97%", ml: 1, height: "100%" }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#232955", fontWeight: 700 }}
          >
            {localization.ON_BOAT.toLocaleUpperCase()}{" "}
          </Typography>
          <Controller
            name="onBoat"
            control={control}
            render={({ field }) => (
              <CustomSwitch
                onChange={(e) => {
                  field.onChange(e);
                  if (!checked) {
                    setOpen(true);
                    setChecked(true);
                  } else {
                    setOpen(false);
                    setChecked(false);
                  }
                  console.log(e.target.checked);
                }}
                checked={checked}
              />
            )}
          />
        </Stack>
        <BoatDialog
          open={open}
          setOpen={setOpen}
          control={control}
          setChecked={setChecked}
        />
      </Box>
    </Container>
  );
};

export default Boat;
