import React from "react";
import { ButtonBase, Container, Typography } from "@mui/material";

const ContinueBtn = ({ id, children, type, pt, pb, onClick }) => {
  return (
    <Container maxWidth="md" disableGutters sx={{ pt: pt, pb: pb }}>
      <ButtonBase
        onClick={onClick}
        type={type}
        id={id}
        sx={{
          background: "#232955",
          width: "100%",
          height: "4em",
          borderRadius: "25px",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontWeight: 700 }}>
          {children}{" "}
        </Typography>
      </ButtonBase>
    </Container>
  );
};

export default ContinueBtn;
