import React, { useContext } from "react";
import GoogleMapReact from "google-map-react";
import { DataContext } from "../App";
import mapStyles from "./mapStyles";

const Map = ({ onGoogleApiLoaded, defaultProps, height }) => {
  const content = useContext(DataContext);

  const options = {
    styles: mapStyles,
    gestureHandling: "greedy",
  };

  return (
    <div
      style={{
        width: "100%",
        height: height,
      }}
    >
      {content.googleMapsApiKey && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: content.googleMapsApiKey,
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={onGoogleApiLoaded}
          options={options}
        ></GoogleMapReact>
      )}
    </div>
  );
};

export default Map;
