import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import moment from "moment";

const Counter = ({ initValue }) => {
  const theme = useTheme();
  const time = moment.utc(initValue * 1000).format("mm:ss");

  return (
    <>
      <Typography
        variant="h6"
        sx={{ color: theme.palette.blue.dark, position: "absolute" }}
      >
        {initValue && time}
      </Typography>
    </>
  );
};

export default Counter;
