import React, {useState} from "react";
import {Typography, Stack, Box} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Collapse} from "@mui/material";
import {localization} from "../localizationV2";
import {ExpandMore} from "../ExpandMore";
import {useTheme} from "@emotion/react";

const Info = ({title, value}) => {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Typography
                variant="body2"
                sx={{color: theme.palette.blue.dark, fontWeight: 600}}
            >
                {title}
            </Typography>
            <Typography
                variant="body2"
                sx={{color: theme.palette.grey, fontWeight: 600}}
            >
                {value}
            </Typography>
        </Stack>
    );
};

const Total = ({tax, baseAmount, taxAmount, currency, total}) => {

    const theme = useTheme()
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const fixedConversionRate = 7.53450;
    const totalAmount = `${total} ${currency}`;
    const totalBaseAmount = `${baseAmount} ${currency} ~ ${(baseAmount * fixedConversionRate).toFixed(2)} HRK`;
    const totalTaxAmount = `${taxAmount} ${currency} ~ ${(taxAmount * fixedConversionRate).toFixed(2)} HRK`;

    return (
        <Box
            sx={{
                background: "white",
                height: "3.5em",
                width: "100%",
                borderBottomLeftRadius: expanded ? 0 : "30px",
                borderBottomRightRadius: expanded ? 0 : "30px",
                borderTop: "1px solid #9A9A9A",
                zIndex: 99,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{height: "100%", width: "90%", ml: 2}}
            >
                <Typography
                    variant="h6"
                    sx={{
                        color: "#232955",
                        fontWeight: 700,
                    }}
                >
                    {localization.TOTAL.toLocaleUpperCase()}: {totalAmount}
                </Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon sx={{fontSize: 45, color: "#232955"}}/>
                </ExpandMore>
            </Stack>
            <Collapse in={expanded} timeout={20} unmountOnExit>
                {" "}
                <Box
                    sx={{
                        background: "white",
                        height: "110%",
                        width: "100%",
                        borderBottomLeftRadius: "30px",
                        borderBottomRightRadius: "30px",
                        zIndex: 99,
                    }}
                >
                    <Stack spacing={1.5} sx={{height: "100%", width: "90%", ml: 2.5}}>
                        <Stack spacing={0.5}>
                            <Info
                                title={localization.BASE_AMOUNT}
                                value={totalBaseAmount}
                            />
                            <Info title={localization.TAX} value={tax + "%"}/>
                            <Info
                                title={localization.TAX_AMOUNT}
                                value={totalTaxAmount}
                            />
                            <Info
                                title={localization.TOTAL}
                                value={(total * fixedConversionRate).toFixed(2) + " HRK"}
                            />
                        </Stack> <Typography
                        variant="body2"
                        sx={{color: theme.palette.blue.dark, fontWeight: 600, textAlign: 'center'}}
                    >
                        Fixed conversion rate: 1 EUR = {fixedConversionRate} HRK
                    </Typography>
                    </Stack>
                </Box>
            </Collapse>
        </Box>
    );
};

export default Total;
