import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { localization } from "../localizationV2";

const Card = ({ title, name, url }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        height: isMatch ? "16em" : "15em",
        width: isMatch ? "13em" : "11em",
        borderRadius: "25px",
        background: "white",
      }}
    >
      <Stack spacing={-0.5} sx={{ m: 1 }}>
        <Typography
          variant="caption"
          sx={{ color: "#232955", fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "#232955", fontWeight: 700 }}>
          {name}
        </Typography>
      </Stack>
      <Box
        sx={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          height: "80%",
          width: "100%",
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 255) 0%, rgba(0, 0, 0, 0) 18%),url(${url})`,
          // backgroundImage: `linear-gradient(to bottom, rgba(255,255,255), rgba(0,0,0,0)),url(${url})`,
          backgroundSize: "cover",
        }}
      ></Box>
    </Box>
  );
};

const BoatAndSkipper = ({ skipperName, boatName, skipperImage, boatImage }) => {
  return (
    <>
      <Box display="flex">
        <Stack direction="row" spacing={1}>
          <Card
            title={localization.BOAT.toLocaleUpperCase()}
            name={boatName}
            url={boatImage}
          />
          <Card
            title={localization.CAPTAIN.toLocaleUpperCase()}
            name={skipperName}
            url={skipperImage}
          />
        </Stack>
      </Box>
    </>
  );
};

export default BoatAndSkipper;
