import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Controller} from "react-hook-form";
import {useTheme} from "@emotion/react";
import {Stack, Typography} from "@mui/material";


const PrivacyAndCookies = ({control, errors}) => {
    const theme = useTheme();

    return (
        <Stack spacing={0} direction={'row'}>
            <Controller
                name="checkbox"
                control={control}
                rules={{required: true}}
                render={({field}) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                size="small"
                                sx={{
                                    "&.MuiCheckbox-root": {
                                        color: errors?.checkbox
                                            ? theme.palette.red
                                            : theme.palette.blue.dark,
                                    },
                                }}
                            />
                        }
                    />
                )}
            />
            <Typography sx={{
                color: errors?.checkbox ? theme.palette.red : theme.palette.white,
                fontSize: "13px",
                fontWeight: 600,
            }}>I have read and understand the <a style={{color: errors?.checkbox ? theme.palette.red : '#ffffff'}}
                                                 href={'privacy'}>Privacy</a> and Cookies Policy.</Typography>
        </Stack>
    );
};

export default PrivacyAndCookies;
