import React from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { localization } from "../localizationV2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const BlueTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#00AEEF",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#9A9A9A",
    },

    "&.Mui-focused fieldset": {
      borderColor: "#00AEEF",
    },
  },
});

const DeclineBox = ({ control, register }) => {
  return (
    <Box
      sx={{
        height: "25em",
        width: "100%",
        background: "white",
        borderRadius: "25px",
      }}
    >
      <Controller
        render={({ field }) => (
          <RadioGroup
            sx={{ m: 2 }}
            onChange={(e) => {
              field.onChange(e);
            }}
            defaultValue={localization.DONT_SHARE}
          >
            <FormControlLabel
              value={localization.DONT_SHARE}
              control={
                <Radio
                  checkedIcon={<CheckCircleIcon />}
                  sx={{
                    color: "#00AEEF",
                    "&.Mui-checked": {
                      color: "#00AEEF",
                    },
                  }}
                />
              }
              label={localization.DONT_SHARE}
              sx={{ color: "#232955", fontWeight: 700 }}
            />

            <FormControlLabel
              value={localization.CANT_CONTACT}
              control={
                <Radio
                  checkedIcon={<CheckCircleIcon />}
                  sx={{
                    color: "#00AEEF",
                    "&.Mui-checked": {
                      color: "#00AEEF",
                    },
                  }}
                />
              }
              label={localization.CANT_CONTACT}
              sx={{ color: "#232955", fontWeight: 700 }}
            />

            <FormControlLabel
              value={localization.OTHER_TRANSPORT}
              control={
                <Radio
                  checkedIcon={<CheckCircleIcon />}
                  sx={{
                    color: "#00AEEF",
                    "&.Mui-checked": {
                      color: "#00AEEF",
                    },
                  }}
                />
              }
              label={localization.OTHER_TRANSPORT}
              sx={{ color: "#232955", fontWeight: 700 }}
            />

            <FormControlLabel
              value={localization.ACCIDENTLY_CALLED}
              control={
                <Radio
                  checkedIcon={<CheckCircleIcon />}
                  sx={{
                    color: "#00AEEF",
                    "&.Mui-checked": {
                      color: "#00AEEF",
                    },
                  }}
                />
              }
              label={localization.ACCIDENTLY_CALLED}
              sx={{ color: "#232955", fontWeight: 700 }}
            />
            <FormControlLabel
              value={localization.OTHER}
              control={
                <Radio
                  checkedIcon={<CheckCircleIcon />}
                  sx={{
                    color: "#00AEEF",
                    "&.Mui-checked": {
                      color: "#00AEEF",
                    },
                  }}
                />
              }
              label={localization.OTHER}
              sx={{ color: "#232955", fontWeight: 700 }}
            />
          </RadioGroup>
        )}
        name="value"
        control={control}
      />

      <BlueTextField
        label={localization.DETAILS}
        multiline
        rows={4}
        sx={{ width: "90%", ml: 2 }}
        name={"message"}
        {...register("message")}
      />
    </Box>
  );
};

export default DeclineBox;
