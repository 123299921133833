import { DataContext } from "../../App";
import React, { useContext } from "react";
import { Link, Stack, Typography, IconButton } from "@mui/material";
import { localization } from "../localizationV2";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { useTheme } from "@emotion/react";

const ContactSupport = () => {
  const content = useContext(DataContext);
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
      sx={{ mt: 4 }}
    >
      <Link href={`tel:${content.phone}`}>
        <IconButton sx={{ border: `2px solid ${theme.palette.white}` }}>
          <LocalPhoneOutlinedIcon
            sx={{ color: theme.palette.white, fontSize: 22 }}
          />
        </IconButton>
      </Link>

      <Typography sx={{ color: "white", fontWeight: 600, fontSize: "14px" }}>
        {localization.SUPPORT}{" "}
        <Link
          sx={{
            color: "white",
            textDecoration: "none",
            fontWeight: 600,
            fontSize: "14px",
          }}
          href={`tel:${content.phone}`}
        >
          {content.phone}
        </Link>
      </Typography>
    </Stack>
  );
};

export default ContactSupport;
