import React, { useEffect } from "react";
import { ButtonBase, Container, Stack, Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { localization } from "./localizationV2";
import { useNavigate } from "react-router-dom";
import ContactSupport from "../v2components/LoadingScreen/ContactSupport";

const UnfulfilledRide = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Typography
          variant="body1"
          sx={{ color: theme.palette.white, fontWeight: 700, mt: 2 }}
        >
          {localization.OH_NO.toLocaleUpperCase()}
        </Typography>
        <Box sx={{ width: "75%" }}>
          <Typography
            sx={{
              color: theme.palette.blue.dark,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {localization.RUSH_HOUR}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.white, fontWeight: 600, mt: 2 }}
        >
          {localization.REFUND.toLocaleUpperCase()}
        </Typography>
        <ButtonBase
          sx={{
            background: theme.palette.white,
            borderRadius: "25px",
            height: "3.5em",
            width: "100%",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          <Typography sx={{ color: theme.palette.blue.dark, fontWeight: 800 }}>
            {localization.BACK_TO_HOME.toLocaleUpperCase()}
          </Typography>
        </ButtonBase>
        <ContactSupport />
      </Stack>
    </Container>
  );
};

export default UnfulfilledRide;
