import React from "react";
import { Stack, ButtonBase, Box, Container } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ zIndex: 3 }}>
      {" "}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={25}
        sx={{ paddingTop: isMatch ? 2 : 1, width: "100%" }}
      >
        <Box sx={{ height: "2.5em", width: "2.5em" }} />
        <ButtonBase
          onClick={() => navigate("/faq")}
          sx={{
            height: "2.5em",
            width: "2.5em",
            borderRadius: "30px",
            border: "2px solid white",
          }}
        >
          <img src="imagesV2/info.svg" alt="" />
        </ButtonBase>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
      >
        <img src="imagesV2/ahoy.svg" alt="" />
        <img src="imagesV2/quote.svg" alt="" />
      </Stack>
    </Container>
  );
};

export default Header;
