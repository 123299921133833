import React from "react";
import { Typography, Container, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Box } from "@mui/material";

const HeaderHome = ({ children }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ background: theme.palette.blue.light }}
    >
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <IconButton
              sx={{ background: theme.palette.white }}
              onClick={() => {
                navigate("/");
              }}
            >
              <HomeIcon sx={{ color: theme.palette.blue.dark }} />
            </IconButton>{" "}
          </Box>
          <Typography
            variant="body1"
            noWrap
            sx={{
              mr: 4,
              flexGrow: 1,
              fontWeight: 700,
              color: "white",
            }}
          >
            {children}{" "}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default HeaderHome;
