import {Button, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useEffect} from "react";
import {StyledDialog} from "./TimePickerDialog";
import Hotjar from "@hotjar/browser";
import TagManager from "react-gtm-module";


const siteId = process.env.REACT_APP_HOT_JAR_SITE_ID;
const hotjarVersion = process.env.REACT_APP_HOT_JAR_VERSION;

export const Subtitle2 = styled(Typography)(({theme}) => ({
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
    letterSpacing: "0.5%",
    fontWeight: 400,
    color: "#80858A",

}));

export function cookieConsentGiven() {
    if (!localStorage.getItem("cookie_consent")) {
        return "undecided";
    }
    return localStorage.getItem("cookie_consent");
}


const CookieConsentPopup = () => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    }
    const [consentGiven, setConsentGiven] = React.useState("");

    useEffect(() => {
        setConsentGiven(cookieConsentGiven());
    }, []);


    const handleAcceptCookies = () => {
        localStorage.setItem("cookie_consent", "yes");
        setConsentGiven("yes");

        if (siteId != null && hotjarVersion != null) {
            Hotjar.init(Number(siteId), Number(hotjarVersion));
        }
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM_ID || 'GTM-WTJHSVB',
            auth: process.env.REACT_APP_GTM_AUTH || 'ljUKr8qUcmugzcnZ7noBQQ',
            preview: process.env.REACT_APP_GTM_PREVIEW || 'env-3',
        }
        TagManager.initialize(tagManagerArgs);
        setOpen(false)
    };

    const handleDeclineCookies = () => {
        localStorage.setItem("cookie_consent", "no");
        setConsentGiven("no");
        setOpen(false)

    };

    return (
        consentGiven === "undecided" && (

            <StyledDialog
                open={open}
                PaperProps={{
                    style: {
                        color: "#232955",
                        borderRadius: "25px",
                        width: "100%",
                    },
                }}
            >
                <DialogTitle sx={{color: "#232955"}}>
                    Welcome to Ahoy! </DialogTitle>
                <DialogContent>
                    <Subtitle2
                        sx={{width: {xs: "100%", lg: "90%"}}}
                    >
                        We use cookies and similar technologies that are necessary to
                        run the app and the website. Additional cookies are only used
                        with your consent. We use them to access, analyse and store
                        information such as the characteristics of your device as well
                        as certain personal data (IP addresses, navigation usage,
                        geolocation data or unique identifiers). The processing of your
                        data serves various purposes: Analytics cookies allow us to
                        analyse our performance to offer you a better online experience
                        and evaluate the efficiency of our campaigns. Personalisation
                        cookies give you access to a customised experience of our
                        website with usage-based offers and support.{" "}
                    </Subtitle2>

                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            height: "2rem",
                            width: "5rem",
                            background: "#232955",
                            color: '#ffffff',
                            border: '1px solid #232955'
                        }}

                        onClick={handleAcceptCookies}
                    >
                        Accept
                    </Button>
                    <Button
                        sx={{height: "2rem", width: "5rem", color: '#232955',}}
                        size="small"
                        onClick={handleDeclineCookies}
                    >
                        Reject
                    </Button>
                </DialogActions>
            </StyledDialog>
        )
    );
};

export default CookieConsentPopup;
