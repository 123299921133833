import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} sx={{ height: "1.5em", width: "1.5em" }} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
