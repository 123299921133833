import React from "react";
import { Dialog, Typography } from "@mui/material";
import { localization } from "../localizationV2";
import { useTheme } from "@mui/material";

const WarningMessage = ({ openWarning, setOpenWarning }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={openWarning}
      onClose={() => setOpenWarning(false)}
      sx={{
        ".MuiDialog-paper": {
          borderRadius: "25px",
          background: theme.palette.red,
          height: "5em",
          width: "16em",
        },
      }}
    >
      <Typography
        sx={{
          color: theme.palette.white,
          m: 2,
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {localization.RED_CIRCLE}
      </Typography>
    </Dialog>
  );
};

export default WarningMessage;
