import React, {useState, useEffect, useCallback, createContext} from "react";
import {Box} from "@mui/system";
import {Routes, Route} from "react-router-dom";
import {Navigate} from "react-router-dom";
import HomeScreen from "./v2components/Home/HomeScreen";
import ConfirmationScreen from "./v2components/ConfirmationScreen/ConfirmationScreen";
import DestinationMapNew from "./v2components/Destination/DestinationMapNew";
import PickUpMap from "./v2components/PickUpLocation/PickUpMap";
import PopularLocationList from "./v2components/Destination/PopularLocationList";
import User from "./v2components/User.js/User";
import Faq from "./v2components/Faq";
import LoadingScreen from "./v2components/LoadingScreen/LoadingScreen";
import RideConfirmed from "./v2components/RideConfirmed/RideConfirmed";
import DeclineRide from "./v2components/DeclineRide/DeclineRide";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Payment from "./v2components/Payment/Payment";
import ActiveRide from "./v2components/ActiveRide/ActiveRide";
import CompletedRide from "./v2components/CompletedRide/CompletedRide";
import RateRide from "./v2components/CompletedRide/RateRide";
import UnfulfilledRide from "./v2components/UnfulfilledRide";
import TagManager from 'react-gtm-module';
import Hotjar from '@hotjar/browser';
import Privacy from "./v2components/Home/Privacy";
import CookieConsentPopup from "./v2components/Home/CookiesConsentPopup";

const siteId = process.env.REACT_APP_HOT_JAR_SITE_ID;
const hotjarVersion = process.env.REACT_APP_HOT_JAR_VERSION;

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || 'GTM-WTJHSVB',
    auth: process.env.REACT_APP_GTM_AUTH || 'ljUKr8qUcmugzcnZ7noBQQ',
    preview: process.env.REACT_APP_GTM_PREVIEW || 'env-3',
}

const cookieConsent = localStorage.getItem("cookie_consent")
if (cookieConsent != null && cookieConsent === "yes") {
    TagManager.initialize(tagManagerArgs);

    if (siteId != null && hotjarVersion != null) {
        Hotjar.init(Number(siteId), Number(hotjarVersion));
    }
}

export const DataContext = createContext();
export const ApiContext = createContext();

const App = () => {
    const [content, setContent] = useState({});

    const theme = createTheme({
        typography: {
            fontFamily: "Montserrat, sans-serif",
            thin: 100,
            extraLight: 200,
            light: 300,
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
            extraBold: 800,
            black: 900,
        },
        palette: {
            blue: {
                dark: "#232955",
                light: "#8ED8F8",
                neon: "#00AEEF",
            },
            red: "#FF2E00",
            grey: "#9A9A9A",
            white: "#FFFFFF",
            textGrey: "#80858A",
        },
    });

    const API = {
        host: process.env.REACT_APP_API,
    };

    const getContent = useCallback(async () => {
        const response = await fetch(`${API.host}/api/v1/settings`);
        const data = await response.json();
        setContent(data);
    }, [API.host]);

    useEffect(() => {
        window.scrollTo(0, 0);

        getContent().catch((e) => console.error(e));
    }, [getContent]);

    return (
        <ThemeProvider theme={theme}>
            <DataContext.Provider value={content}>
                <ApiContext.Provider value={API}>
                    <CookieConsentPopup/>

                    <Box
                        sx={{
                            background: "#8ED8F8",
                            minHeight: "100vh", position: 'relative'
                        }}
                    >
                        <Routes>
                            <Route path="/" element={<HomeScreen/>}/>
                            <Route path="/faq" element={<Faq/>}/>
                            <Route path="/search" element={<PopularLocationList/>}/>
                            <Route path="/destination" element={<DestinationMapNew/>}/>
                            <Route path="/pickup" element={<PickUpMap/>}/>
                            <Route path="/confirmRide" element={<ConfirmationScreen/>}/>
                            <Route path="/user/" element={<User/>}/>
                            <Route path="/ride/:id" element={<LoadingScreen/>}/>
                            <Route path="/rideConfirmed" element={<RideConfirmed/>}/>
                            <Route path="/decline" element={<DeclineRide/>}/>
                            <Route path="/payment/:id" element={<Payment/>}/>
                            <Route path="/active" element={<ActiveRide/>}/>
                            <Route path="/completed" element={<CompletedRide/>}/>
                            <Route path="/rate" element={<RateRide/>}/>
                            <Route path="/unfulfilled" element={<UnfulfilledRide/>}/>
                            <Route path="/privacy" element={<Privacy/>}/>
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                    </Box>
                </ApiContext.Provider>
            </DataContext.Provider>
        </ThemeProvider>
    );
};

export default App;
